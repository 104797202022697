import {AdminComponent} from './components/admin-view/admin.component'
import {CommonModule} from '@angular/common'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {RouterModule} from '@angular/router'
import {
    ModuleWithProviders,
    NgModule
} from '@angular/core'
import {FileSizePipe} from './pipes/file-size.pipe'
import {PdfModalComponent} from './components/pdf-modal/pdf-modal.component'
import {PdfViewerModule} from 'ng2-pdf-viewer'
import {SysLayoutModule} from '../sys-layout/sys-layout.module'
import {FieldFilterPipe} from './pipes/field-filter.pipe'
import {NavigationComponent} from './components/navigation/navigation.component'
import {AgePipe} from './pipes/age.pipe'
import {SafePipePipe} from './pipes/safe-pipe.pipe'
import {IfRolesDirective} from './directives/if-roles.directive'
import {ImageModalComponent} from './components/image-modal/image-modal.component'
import {CropImageModalComponent} from './components/crop-image-modal/crop-image-modal.component'
import {ImageCropperModule} from 'ngx-image-cropper'
import {ShowPdfModalComponent} from './components/show-pdf-modal/show-pdf-modal.component'
import {NFSpinnerComponent} from './components/nf-spinner/nf-spinner.component'
import {NFTablePaginationComponent} from './components/nf-table-pagination/nf-table-pagination.component'
import {AngularImageViewerModule} from "angular-x-image-viewer";

// import {LayoutModule} from '../../../app/modules/layout/layout.module'


@NgModule({
    declarations: [
        AdminComponent,
        FileSizePipe,
        PdfModalComponent,
        ShowPdfModalComponent,
        ImageModalComponent,
        CropImageModalComponent,
        FieldFilterPipe,
        NavigationComponent,
        AgePipe,
        SafePipePipe,
        IfRolesDirective,
        NFSpinnerComponent,
        NFTablePaginationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SysLayoutModule,
        PdfViewerModule,
        ImageCropperModule,
        AngularImageViewerModule,
        // LayoutModule
    ],
    exports: [
        AdminComponent,
        SysLayoutModule,
        FileSizePipe,
        PdfModalComponent,
        ShowPdfModalComponent,
        ImageModalComponent,
        CropImageModalComponent,
        FieldFilterPipe,
        NavigationComponent,
        AgePipe,
        SafePipePipe,
        IfRolesDirective,
        NFSpinnerComponent,
        NFTablePaginationComponent
    ],
    entryComponents: [
        PdfModalComponent,
    ],
})
export class SysSharedModule {
    static forRoot(): ModuleWithProviders<SysSharedModule> {
        return {
            ngModule: SysSharedModule,
        }
    }
}
