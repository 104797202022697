import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {IUser} from '../../sys-admin/models/user'
import {Module, UserUpdate, View} from '../../../../app/api/graphql'
import {Router} from '@angular/router'
import {ThemeService} from '../../sys-shared/services/theme.service'
import {UpdateUserMutation} from '../../sys-admin/api/updateUser.mutation'
import {SwalService} from '../../sys-shared/services/swal.service'

@Component({
    selector: 'app-menu-tabs',
    templateUrl: './menu-tabs.component.html',
    styleUrls: ['./menu-tabs.component.css']
})
export class MenuTabsComponent implements OnInit {

    @Input() navTab: string = 'menu'
    @Input() sidebarVisible: boolean = true
    @Input() currentActiveMenu
    @Input() currentActiveSubMenu
    @Input() user: IUser = null
    @Input() darkClass: string = ''
    @Input() themeClass: string = ''
    @Output() activeInactiveMenuEvent = new EventEmitter()
    constructor(private router: Router,
                private themeService: ThemeService,
                private updateUserGQL: UpdateUserMutation) {
    }

    ngOnInit(): void {
    }

    changeNavTab(tab: string) {
        this.navTab = tab
    }

    activeInactiveMenu(menuItem: string) {
        this.activeInactiveMenuEvent.emit(menuItem)
    }

    activeSubMenu(module: Module, view: View) {
        const url = '/' + module.code + '/' + this.currentActiveSubMenu
        return view.url === url
    }

    goAbout() {
        this.router.navigate(['/about-us/about'])
    }

    changeDarkMode(darkClass: string) {
        this.themeService.changeDarkMode(darkClass)
        this.darkClass = darkClass
        const user: UserUpdate = {
            theme: darkClass
        }
        this.updateThemePreferences(user)
    }

    changeTheme(theme: string) {
        this.themeService.themeChange(theme)
        this.themeClass = theme
        const user: UserUpdate = {
            skin: theme
        }
        this.updateThemePreferences(user)
    }

    updateThemePreferences(user: UserUpdate) {
        this.updateUserGQL
            .mutate({
                idUser: this.user.id,
                user: user
            })
            .toPromise()
            .then((result) => {
                // SwalService.success('Ok!', 'Se actualizo el usuario')
            })
            .catch((err) => {
                SwalService.error('Error', 'No se pudo actualizar los datos del usuario!')
            })
    }

}
