import { Injectable } from "@angular/core";
// DOMAIN IMPORTS
import {BranchOffice, Brand, Category, NewProduct, Product, Variant} from '../domain/product.entity'
import { ProductRepository } from "../domain/product.repository";

@Injectable({
    providedIn: 'root'
})
export class ProductRepositoryMockService extends ProductRepository {

    dataProducts: Product[] = [
        {
            barCode     :   '22123456789',
            category    :   'Tecnologia',
            variant     :   'Laptops',
            name        :   'Notebook HP',
            brand       :   'HP',
            unitMeasure :   'Unidad',
            unitPrice   :   1200.4,
            description :   'Laptop de escritorio, Memoria 4GB, Panta IPS HD',
            urlImage    :   'assets/images/laptophp.jpg',
            stores      :   [{ id: '1111', name: 'Real Plaza Cusco', department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                            { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                            { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' }],
            registeredAt:   '07/09/2021-16:56:35'
        },
        {
            barCode     :   '33123456789',
            category    :   'Tecnologia',
            variant     :   'televisores',
            name        :   'Televisor SAMSUNG',
            brand       :   'SAMSUNG',
            unitMeasure :   'Unidad',
            unitPrice   :   3299.0,
            description :   'Televisor SAMSUNG CRYSTAL UHD 65" Ultra HD / 4K Smart TV UN65AU7000GXPE',
            urlImage    :   'assets/images/tvsamsung.jpg',
            stores      :   [{ id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                            { id: '1111', name: 'Real Plaza Lima',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                            { id: '1111', name: 'Real Plaza Miaflores',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' }],
            registeredAt:   '07/09/2021-16:56:35'
        },
        {
            barCode     :   '44123456789',
            category    :   'Abarrotes',
            variant     :   'Conservas',
            name        :   'Atum PRIMOR',
            brand       :   'PRIMOR',
            unitMeasure :   'Unidad',
            unitPrice   :   5.79,
            description :   'Trozos de Atún PRIMOR en Aceite Vegetal Lata 170g',
            urlImage    :   'assets/images/atum.jpg',
            stores      :   [{ id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                            { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                            { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' }],
            registeredAt:   '07/09/2021-16:56:35'
        },
        {
            barCode     :   '44123456789',
            category    :   'Abarrotes',
            variant     :   'Conservas',
            name        :   'Atum PRIMOR',
            brand       :   'PRIMOR',
            unitMeasure :   'Unidad',
            unitPrice   :   5.79,
            description :   'Trozos de Atún PRIMOR en Aceite Vegetal Lata 170g',
            urlImage    :   'assets/images/atum.jpg',
            stores      :   [{ id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' }],
            registeredAt:   '07/09/2021-16:56:35'
        },
        {
            barCode     :   '44123456789',
            category    :   'Abarrotes',
            variant     :   'Conservas',
            name        :   'Atum PRIMOR',
            brand       :   'PRIMOR',
            unitMeasure :   'Unidad',
            unitPrice   :   5.79,
            description :   'Trozos de Atún PRIMOR en Aceite Vegetal Lata 170g',
            urlImage    :   'assets/images/atum.jpg',
            stores      :   [{ id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' }],
            registeredAt:   '07/09/2021-16:56:35'
        },
        {
            barCode     :   '44123456789',
            category    :   'Abarrotes',
            variant     :   'Conservas',
            name        :   'Atum PRIMOR',
            brand       :   'PRIMOR',
            unitMeasure :   'Unidad',
            unitPrice   :   5.79,
            description :   'Trozos de Atún PRIMOR en Aceite Vegetal Lata 170g',
            urlImage    :   'assets/images/atum.jpg',
            stores      :   [{ id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' }],
            registeredAt:   '07/09/2021-16:56:35'
        },
        {
            barCode     :   '44123456789',
            category    :   'Abarrotes',
            variant     :   'Conservas',
            name        :   'Atum PRIMOR',
            brand       :   'PRIMOR',
            unitMeasure :   'Unidad',
            unitPrice   :   5.79,
            description :   'Trozos de Atún PRIMOR en Aceite Vegetal Lata 170g',
            urlImage    :   'assets/images/atum.jpg',
            stores      :   [{ id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' },
                { id: '1111', name: 'Real Plaza Cusco',  department: 'Cusco', province: 'Cusco', district: 'CUsco', address: 'Av.Cultura' }],
            registeredAt:   '07/09/2021-16:56:35'
        }
    ]
    dataBranchOffices: BranchOffice[] = [
        {
            id: '1111',
            name: 'Cusco Imperial',
            department: 'Cusco',
            province: 'Cusco',
            district: 'Cusco',
            address: 'Av.Cultura - 234'
        },
        {
            id: '2222',
            name: 'PLaza Vea Miraflores',
            department: 'Lima',
            province: 'Lima',
            district: 'Miraflores',
            address: 'Av. Argentina S/N'
        },
        {
            id: '3333',
            name: 'La Canasta',
            department: 'Cusco',
            province: 'Cusco',
            district: 'Wanchaq',
            address: 'Av. Cusco - 234'
        }
    ]
    dataVariants: Variant[] = [
        {
            id: '1',
            idCat: '1111',
            name: 'Televisores'
        },
        {
            id: '2',
            idCat: '1111',
            name: 'Computo'
        },
        {
            id: '3',
            idCat: '1111',
            name: 'Telefonia'
        },
        {
            id: '4',
            idCat: '1111',
            name: 'Videojuegos'
        },
        {   id: '5',
            idCat: '2222',
            name: 'Lavado'
        },
        {   id: '6',
            idCat: '2222',
            name: 'Refrigeracion'
        },
        {   id: '7',
            idCat: '2222',
            name: 'Cuidado Personal'
        },
        {   id: '8',
            idCat: '2222',
            name: 'Limpieza y Aspirado'
        },
        {   id: '9',
            idCat: '2222',
            name: 'Cocinas'
        },
        {   id: '10',
            idCat: '3333',
            name: 'Moda Bebe e Infantil'
        },
        {   id: '11',
            idCat: '3333',
            name: 'Moda Hombre'
        },
        {   id: '12',
            idCat: '3333',
            name: 'Zapatillas Mujer'
        },
        {   id: '13',
            idCat: '3333',
            name: 'Zapatillas Hombre'
        },
        {   id: '14',
            idCat: '3333',
            name: 'Moda Infantil femenina'
        },
        {   id: '15',
            idCat: '4444',
            name: 'Bisuteria'
        },
        {   id: '16',
            idCat: '4444',
            name: 'Relojes'
        },
        {   id: '17',
            idCat: '4444',
            name: 'Carteras y Accesorios de Belleza'
        },
        {   id: '18',
            idCat: '4444',
            name: 'Tratamiento'
        },
        {   id: '19',
            idCat: '4444',
            name: 'Perfumes'
        }
    ]
    dataCategories: Category[] = [
        {
            id: '1111',
            name: 'Technology',
            description: 'Lorem Ipsum asdadsasdasd'


        },
        {
            id: '2222',
            name: 'ELectrohogar',
            description: 'Lorem Ipsum asdadsasdasd'

        },
        {
            id: '3333',
            name: 'Moda',
            description: 'Lorem Ipsum asdadsasdasd'

        },
        {
            id: '4444',
            name: 'Belleza y Accesorios',
            description: 'Lorem Ipsum asdadsasdasd'
        }
    ]
    dataBrands: Brand[] = [
        {
            id: '1',
            idCat: '1111',
            name: 'LG'
        },
        {
            id: '2',
            idCat: '1111',
            name: 'Samsung'
        },
        {
            id: '3',
            idCat: '1111',
            name: 'HP'
        },
        {
            id: '4',
            idCat: '2222',
            name: 'Mabe'
        },
        {
            id: '5',
            idCat: '2222',
            name: 'Oster'
        },
        {
            id: '6',
            idCat: '3333',
            name: 'Hypnotic'
        },
        {
            id: '7',
            idCat: '3333',
            name: 'Adidas'
        },
        {
            id: '8',
            idCat: '4444',
            name: "L'orel"
        }

    ]
    constructor() { super() }

    getProductByCode(id: string): Promise<Product> {
        const p = this.dataProducts.find( value => {
            return value.barCode === id
        } )
        return new Promise( (res, rej) => {
            setTimeout( () => {
                res(p)
            }, 200 )
        } )
    }
    getVariants(id: string): Promise<Variant[]> {
        const variantsSelectedById = this.dataVariants.filter( variant => {
            return variant.idCat === id
        })
        return new Promise( (res, rej) => {
            setTimeout( () => {
                res(variantsSelectedById)
            }, 10 )
        } )
    }
    getProducts(): Promise<Product[]> {
        console.log('hola')
        return new Promise( (res, rej) => {
            setTimeout( () => {
                res(this.dataProducts)
            }, 500 )
        } )
    }
    getCategories(): Promise<Category[]> {
        return new Promise( (res, rej) => {
            setTimeout( () => {
                res(this.dataCategories)
            }, 100 )
        } )
    }
    updateProduct(id: string, updatedProduct: Product): Promise<string> {
        for ( const i in this.dataProducts) {
            if ( this.dataProducts[i].barCode === updatedProduct.barCode ) {
                this.dataProducts[i].category       = updatedProduct.category,
                this.dataProducts[i].variant        = updatedProduct.variant,
                this.dataProducts[i].name           = updatedProduct.name,
                this.dataProducts[i].brand          = updatedProduct.brand,
                this.dataProducts[i].unitMeasure    = updatedProduct.unitMeasure,
                this.dataProducts[i].unitPrice      = updatedProduct.unitPrice,
                this.dataProducts[i].description    = updatedProduct.description,
                this.dataProducts[i].urlImage       = updatedProduct.urlImage,
                this.dataProducts[i].stores         = updatedProduct.stores,
                this.dataProducts[i].registeredAt   = updatedProduct.registeredAt
                break
            }
        }
        return new Promise( (res, rej) => {
            setTimeout( () => {
                res( 'updated' )
            }, 100)
        })
    }
    saveProduct(newProduct: NewProduct): Promise<string> {
        const cat = this.dataCategories.filter( ( c) => newProduct.codCategory === c.id )[0].name
        const vari = this.dataVariants.filter( (v) => newProduct.codVariant === v.id )[0].name
        const brand = this.dataBrands.filter( (b) => newProduct.codVariant === b.id )[0].name
        const pstores: BranchOffice[] = []
        this.dataBranchOffices.forEach( (bo) => {
            newProduct.codStores.forEach( (id) => {
                if ( id === bo.id ) {
                    pstores.push(bo)
                }
            } )
        } )
        console.log('Storessss', pstores)
        // Build data of product
        const p: Product = {
            barCode: newProduct.barCode,
            brand: brand,
            category: cat,
            description: newProduct.description,
            name: newProduct.name,
            registeredAt: newProduct.registeredAt,
            stores: pstores,
            unitPrice: newProduct.unitPrice,
            unitMeasure: 'Unit',
            urlImage: newProduct.urlImage,
            variant: vari
        }
        this.dataProducts.push( p )
        return new Promise( (res, rej) => {
            setTimeout( () => {
                res('saved')
            }, 100 )
        } )
    }
    deleteProduct(id: string): Promise<string> {
        console.log('luego de eliminar', this.dataProducts)
        this.dataProducts = this.dataProducts.filter( (product) => {
            if ( product.barCode !== id ) {
                return product
            }
        } )

        return new Promise( (res, rej) => {
            setTimeout( () => {
                res('deleted')
            }, 100 )
        } )
    }

    getBranchOffices(): Promise<BranchOffice[]> {
        return new Promise(  ((resolve, reject) => {
            setTimeout( () => {
                resolve( this.dataBranchOffices )
            }, 100)
        }))
    }
    getBrands(id: string): Promise<Brand[]> {
        const brandsSelectedById = this.dataBrands.filter( brand => {
            return brand.idCat === id
        })
        return new Promise( ((resolve, reject) => {
            setTimeout( () =>{
                resolve(brandsSelectedById)
                resolve(brandsSelectedById)
            }, 100 )
        }) )
    }

}
