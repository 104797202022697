import {HttpClient} from '@angular/common/http'
import {UserRepository} from '../../domain/user.repository'
import {User} from '../../domain/user.entity'
import {environment} from '../../../../../environments/environment'
import {Injectable} from '@angular/core'

@Injectable({
    providedIn: 'root'
})
export class UserRestRepository extends UserRepository {

    url: string = environment.url
    constructor(private http: HttpClient) {
        super()
    }
    getUsers(): Promise<User[]> {
        return this.http
            .get<User[]>(`${this.url}/user`).toPromise()
    }
    getUser(id: string): Promise<User> {
        return this.http
            .get<User>(`${this.url}/user/${id}`).toPromise()
    }
    updateUser(id: string, user: User): Promise<User> {
        return this.http
            .post<User>(`${this.url}/user/${id}`, user).toPromise()
    }

}
