import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'lodash'

@Pipe({
    name: 'fieldFilter'
})
export class FieldFilterPipe implements PipeTransform {

    transform(array: any[], field: string, val: string): any {
        if (field && val) {
            let fieldDots = field.split('.')
            if (fieldDots.length == 1) {
                return _.filter(array, row => row[field].toLowerCase().indexOf(val.toLowerCase()) > -1)
            } else if (fieldDots.length == 2) {
                return _.filter(array, row => row[fieldDots[0]][fieldDots[1]].toLowerCase().indexOf(val.toLowerCase()) > -1)
            }
        }
        return array;
    }

}
