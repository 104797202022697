<!--<div id="left-sidebar" class="sidebar">-->
<!--    <div class="sidebar-scroll">-->

<ul class="nav nav-tabs">
    <li class="nav-item" style="cursor: pointer">
        <a class="nav-link"
           [ngClass]="{'active':navTab == 'menu'}"
           (click)="changeNavTab('menu')">Menu
        </a>
    </li>
    <li class="nav-item" style="cursor: pointer">
        <a class="nav-link" [ngClass]="{'active':navTab == 'setting'}"
           (click)="changeNavTab('setting')">
            <i class="icon-settings"></i>
        </a>
    </li>
</ul>

<div class="tab-content p-l-0 p-r-0">
    <div class="tab-pane" id="menu" [ngClass]="{'active':navTab == 'menu' || !navTab}">
        <nav id="left-sidebar-nav" class="sidebar-nav">
            <ul id="main-menu" class="metismenu">
                <li routerLinkActive="active"
                    *ngFor="let module of user?.modules"
                    [routerLinkActiveOptions]="{exact: true}"
                    [ngClass]="{'active':currentActiveMenu == module.code}">
                    <a class="has-arrow" (click)="activeInactiveMenu(module.code)">
                        <i class="{{module.icon}}"></i>
                        <span>{{module.name}}</span>
                    </a>
                    <ul *ngIf="currentActiveMenu == module.code">
                        <li *ngFor="let view of module.views"
                            [ngClass]="{'active': activeSubMenu(module, view)}">
                            <a [routerLink]="[view.url]"
                               title="{{view.description}}">{{view.name}}</a>
                        </li>
                    </ul>
                </li>
                <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <a (click)="goAbout()">
                        <i class="icon-compass"></i><span>A cerca del sistema</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <div class="tab-pane p-l-15 p-r-15" id="setting" [ngClass]="{'active':navTab == 'setting'}">
        <h6>Aspecto</h6>
        <ul class="choose-skin list-unstyled">
            <li data-theme="white" [ngClass]="{'active':darkClass == ''}"
                (click)="changeDarkMode('')">
                <div class="white"></div>
                <span>Claro</span>
            </li>
            <li data-theme="black" [ngClass]="{'active':darkClass == 'full-dark'}"
                (click)="changeDarkMode('full-dark')">
                <div class="black"></div>
                <span>Oscuro</span>
            </li>
        </ul>
        <hr>
        <h6>Tema</h6>
        <ul class="choose-skin list-unstyled">
            <li data-theme="purple" [ngClass]="{'active':themeClass == 'theme-purple'}"
                (click)="changeTheme('theme-purple')">
                <div class="purple"></div>
                <span>Purpura</span>
            </li>
            <li data-theme="blue" [ngClass]="{'active':themeClass == 'theme-blue'}"
                (click)="changeTheme('theme-blue')">
                <div class="blue"></div>
                <span>Azul</span>
            </li>
            <li data-theme="cyan" [ngClass]="{'active':themeClass == 'theme-cyan'}"
                (click)="changeTheme('theme-cyan')">
                <div class="cyan"></div>
                <span>Cyan</span>
            </li>
            <li data-theme="green" [ngClass]="{'active':themeClass == 'theme-green'}"
                (click)="changeTheme('theme-green')">
                <div class="green"></div>
                <span>Verde</span>
            </li>
            <li data-theme="orange" [ngClass]="{'active':themeClass == 'theme-orange'}"
                (click)="changeTheme('theme-orange')">
                <div class="orange"></div>
                <span>Naranja</span>
            </li>
            <li data-theme="blush" [ngClass]="{'active':themeClass == 'theme-blush'}"
                (click)="changeTheme('theme-blush')">
                <div class="blush"></div>
                <span>Rojo</span>
            </li>
        </ul>
        <hr>
        <!--                <h6>General Settings</h6>-->
        <!--                <ul class="setting-list list-unstyled">-->
        <!--                    <li>-->
        <!--                        <label class="fancy-checkbox">-->
        <!--                            <input type="checkbox" name="checkbox">-->
        <!--                            <span>Report Panel Usag</span>-->
        <!--                        </label>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                        <label class="fancy-checkbox">-->
        <!--                            <input type="checkbox" name="checkbox" checked>-->
        <!--                            <span>Email Redirect</span>-->
        <!--                        </label>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                        <label class="fancy-checkbox">-->
        <!--                            <input type="checkbox" name="checkbox" checked>-->
        <!--                            <span>Notifications</span>-->
        <!--                        </label>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                        <label class="fancy-checkbox">-->
        <!--                            <input type="checkbox" name="checkbox">-->
        <!--                            <span>Auto Updates</span>-->
        <!--                        </label>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                        <label class="fancy-checkbox">-->
        <!--                            <input type="checkbox" name="checkbox">-->
        <!--                            <span>Offline</span>-->
        <!--                        </label>-->
        <!--                    </li>-->
        <!--                    <li>-->
        <!--                        <label class="fancy-checkbox">-->
        <!--                            <input type="checkbox" name="checkbox">-->
        <!--                            <span>Location Permission</span>-->
        <!--                        </label>-->
        <!--                    </li>-->
        <!--                </ul>-->
    </div>
</div>
