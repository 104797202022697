import {Injectable} from '@angular/core'
import {Router} from '@angular/router'
import {Apollo} from 'apollo-angular'
import {Company, Role, User} from '../../../../app/api/graphql'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {IUser} from '../../sys-admin/models/user'
import {HttpClient} from '@angular/common/http'
import {GetLoginTQuery} from '../../sys-admin/api/getLoginT.query'
import * as _ from 'lodash'

interface BodyLogin {
    nick: string,
    password: string
}

interface BodyAuth {
    user: string,
    password: string,
    newPassword: string
}

@Injectable({
    providedIn: 'root'
})
export class LoginApi {
    TOKEN: string = 'token'

    user: IUser = null
    public subjectUser = new BehaviorSubject<IUser>(this.user)

    company: Company = null
    public subjectCompany = new BehaviorSubject<Company>(this.company)

    constructor(private http: HttpClient,
                private router: Router,
                private apollo: Apollo,
                private getLoginTQuery: GetLoginTQuery) {
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem(this.TOKEN)    // Check whether the token is expired and return
        // true or false
        // console.log('token', token)
        return token != null && token !== ''
    }

    evaluateRoles(expectedRoles: string[], userRoles: Role[]): boolean {
        for (const userR of userRoles) {
            for (const expR of expectedRoles) {
                if (userR.name === expR) {
                    return true
                }
            }
        }
        return false
    }

    logout() {
        localStorage.removeItem(this.TOKEN)
        this.user = null
        this.subjectUser.next(this.user)
        this.router.navigate(['/authentication/page-login'])
    }

    async loadMenuFake() {
        return
    }

    async loadMenu() {
        await this.getInitialUser()
    }

    async getLoginT() {
        try {
            const query$ = this.getLoginTQuery.fetch({}, {fetchPolicy: 'network-only'})
            const response = await query$.toPromise()
            return response.data
        } catch (e) {
            console.log(e)
            this.logout()
        }
    }

    redirectToFirstView() {
        // set default first module and first view
        if (this.router.url === '/authentication/page-login' &&
            this.user.modules.length > 0 && this.user.modules[0].views.length > 0) {
            this.router.navigate([this.user.modules[0].views[0].url])
        }
    }

    getRoles(): Observable<any> {
        return this.getLoginTQuery.fetch({}, {fetchPolicy: 'network-only'})
    }

    async getInitialUser(): Promise<IUser> {
        const token = localStorage.getItem(this.TOKEN)
        if (!token) {
            this.router.navigate(['authentication/page-login'])
            return null
        } else {
            if (this.user) {
                return this.user
            }
            const loginData = await this.getLoginT()
            // JSON.parse(JSON.stringify(food))
            this.user = _.cloneDeep(loginData.login as IUser)
            this.company = loginData.getCompany
            console.log(this.user)
            localStorage.setItem('token', this.user.token)
            this.subjectUser.next(this.user)
            this.subjectCompany.next(this.company)

            this.redirectToFirstView()
            // // this.subjectUser.next(this.user)
            return this.user as IUser
        }
    }

    async getCurrentUser() {
        console.log('despues', this.user)
        // return this.user
        return await this.getInitialUser()
    }

    // eliminar y usar getCurrentUser
    getUser(): Observable<IUser> {
        return this.subjectUser.asObservable()
    }

    checkRoles(user: IUser): IUser {
        user._roles = {}
        user.roles.forEach((role) => {
            user._roles[role.name] = true
        })
        console.log(user)
        return user
    }

    getCompany(): Observable<Company> {
        return this.subjectCompany.asObservable()
    }

}
