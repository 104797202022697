import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {TableRepository} from "./domain/table.repository";
import {TableRepositoryService} from "./repository/table.repository.service";
import {ViewTablesComponent} from "./ui/view-tables/view-tables.component";



@NgModule({
    declarations: [
    ],
    imports: [
        CommonModule
    ],
    providers: [
        { provide: TableRepository, useClass: TableRepositoryService }
    ]
})
export class ViewTablesModule { }
