import {Injectable} from "@angular/core";
import {Query} from "apollo-angular";
import gql from 'graphql-tag'
import {Order} from "../../../../api/graphql";

interface Response {
    getOrders: Order[]
}

@Injectable({
    providedIn: 'root'
})
export class GetOrdersQuery extends Query<Response> {
    document = gql`
        query{
            getOrders {
                id
                createdAt
                status
                tables {
                    id
                    tableNumber
                }
            }
        }
    `
}
