import {Injectable} from '@angular/core'
import {TableRepository} from "../domain/table.repository";
import {GetTablesQuery} from './graphql/getTables.query'
import {Order, Table, TableInput, TableUpdate} from '../../../api/graphql'
import {GetTableQuery} from './graphql/getTable.query'
import {AddTableMutation} from './graphql/addTable.mutation'
import {RemoveTableMutation} from './graphql/removeTable.mutation'
import {UpdateTableMutation} from './graphql/updateTable.mutation';

@Injectable({
    providedIn: 'root'
})
export class TableRepositoryService implements TableRepository {

    constructor(private getTablesQuery: GetTablesQuery,
                private getTableQuery: GetTableQuery,
                private addTableMutation: AddTableMutation,
                private removeTableMutation: RemoveTableMutation,
                private updateTableMutation: UpdateTableMutation) {
    }

    async getTables(): Promise<Table[]> {
        try {
            const query$ = this.getTablesQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getTables
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async getTable(idTable: string): Promise<Table> {
        try {
            const query$ = this.getTableQuery.fetch({idTable}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getTable
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async addTable(table: TableInput): Promise<Table> {
        try {
            const mutation$ = this.addTableMutation.mutate({table}, {fetchPolicy: 'network-only'})
            const {data} = await mutation$.toPromise()
            return data.addTable
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async updateTable(idTable: string, table: TableUpdate): Promise<string> {
        try {
            const mutation$ = this.updateTableMutation.mutate({idTable, table}, {fetchPolicy: 'network-only'})
            const {data} = await mutation$.toPromise()
            return data.updateTable
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }
    async removeTable(idTable: string): Promise<boolean> {
        try {
            const query$ = this.removeTableMutation.mutate({idTable}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.removeTable
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }
}
