<div class="card-body">
    <div *ngIf="fullUrl; else arrayUr" style="margin-bottom: -8px">
        <!--        <iframe [src]="fullUrl | safePipe: 'resourceUrl'" style="width:100%; height:90vh;" loading="lazy">-->

        <!--        </iframe>-->
        <div style=" overflow:auto;
    white-space:nowrap;">
            <!--        <ng-container *ngIf="arrayUrls">-->
            <!--            -->
            <!--            <img [src]="arrayUrls">-->

            <!--        </ng-container>-->
            <ng-container *ngIf="fullUrl">
                <iframe [src]="fullUrl | safePipe: 'resourceUrl'" style="width:100%; height:90vh;" loading="lazy">
                </iframe>
            </ng-container>
        </div>
        <!--    <img [src]="fullUrl">-->
    </div>
    <ng-template #arrayUr>
        <div *ngIf="arrayUrls ; else noUrl" style="margin-bottom: -8px">
            <div style=" overflow:auto;white-space:nowrap;">
                <ng-container *ngFor="let array of arrayUrls">
                    <iframe [src]="array.url | safePipe: 'resourceUrl'" style="width:100%; height:90vh;" loading="lazy"
                            class="border-mcs mr-2">
                    </iframe>
                </ng-container>
            </div>

        </div>
    </ng-template>
    <ng-template #noUrl>
        Url Invalida
    </ng-template>
</div>
