import {Injectable} from "@angular/core";
import {Query} from "apollo-angular";
import {Food} from "../../../../api/graphql";
import gql from 'graphql-tag'
interface Response {
    getFood: Food
}

@Injectable({
    providedIn: 'root'
})
export class GetFoodQuery extends Query<Response> {
    document = gql`
        query($idFood: ID!)  {
            getFood(idFood: $idFood) {
                id
                name
                price
                category{
                    id
                    name
                }
                images {
                    id
                    name
                    url
                }
                type
                description
            }
        }
    `
}
