import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {SidebarComponent} from './sidebar/sidebar.component'
import {PageLoaderComponent} from './page-loader/page-loader.component'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap'
import {RouterModule} from '@angular/router'
import {NgSelectModule} from '@ng-select/ng-select'
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { ItemsTrComponent } from './items-tr/items-tr.component';
import { UserPhotoComponent } from './user-photo/user-photo.component';
import { MenuTabsComponent } from './menu-tabs/menu-tabs.component'

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        HeaderComponent,
        SidebarComponent,
        PageLoaderComponent,
        ItemsTrComponent,
        UserPhotoComponent,
        MenuTabsComponent
    ],
    exports: [
        HeaderComponent,
        SidebarComponent,
        PageLoaderComponent
    ]
})
export class SysLayoutModule {
}
