import {LOCALE_ID, NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import { HttpClientModule } from '@angular/common/http'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap'
import {SysSharedModule} from '../core/modules/sys-shared/sys-shared.module'
import {ToastrModule} from 'ngx-toastr'
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown'
import {GraphQLModule} from '../core/graphql.module'
import {NgxEchartsModule} from 'ngx-echarts'
import {ThemeService} from '../core/modules/sys-shared/services/theme.service'
import {UsersGuardGuard} from '../core/modules/sys-shared/services/users-guard.guard'
import {AuthGuardService} from '../core/modules/sys-shared/services/auth-guard.service'
import {registerLocaleData} from '@angular/common'
import localeEsPe from '@angular/common/locales/es-PE'
import {UsersModule} from './modules/users/users.module'
import {ChefsModule} from "./modules/chefs/chefs.module";
import {FoodsModule} from "./modules/foods/foods.module";

import {ViewTablesModule} from "./modules/view-table/view-tables.module";
import {CategoriesModule} from "./modules/categories/categories.module";
import {OrderDetailsModule} from "./modules/order-detail/order-detail.module";
import { PruebaComponent } from './modules/prueba/prueba/prueba.component';
import {OrdersWaiterModule} from './modules/orders-waiter/orders-waiter.module'
import {ProductsModule} from "./modules/products/products.module";

registerLocaleData(localeEsPe, 'es-PE')

@NgModule({
    declarations: [
        AppComponent,
        PruebaComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        SysSharedModule.forRoot(),
        ToastrModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        GraphQLModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        HttpClientModule,
        UsersModule,
        ViewTablesModule,
        ChefsModule,
        OrdersWaiterModule,
        FoodsModule,
        CategoriesModule,
        OrderDetailsModule,
        ProductsModule
    ],
    providers: [
        ThemeService,
        UsersGuardGuard,
        AuthGuardService,
        {provide: LOCALE_ID, useValue: 'es-PE'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
