import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Order} from '../../../../api/graphql'

interface Response {
    getOrder: Order
}
interface Param {
    idOrder: string
}

@Injectable({
    providedIn: 'root'
})
export class GetOrderQuery extends Query<Response, Param> {
    document = gql`
        query($id:ID!){
            getOrder(idOrder:$id){
                code
                createdAt
                id
                status
                tables{
                    id
                    tableNumber
                    status
                }
                waiter{
                    names
                    active
                    dni
                }
            }
        }
    `
}
