import {Injectable} from '@angular/core'
import gql from 'graphql-tag'
import {Mutation} from 'apollo-angular'
import {TableUpdate} from '../../../../api/graphql'

interface Response {
    updateTable: string
}

interface Param {
    idTable: string
    table: TableUpdate
}

@Injectable({
    providedIn: 'root'
})
export class UpdateTableMutation extends Mutation<Response, Param> {
    document = gql`
        mutation ($idTable:ID!, $table: TableUpdate!) {
            updateTable(idTable: $idTable, table: $table) {
                createdAt
                tableNumber
                chairsNumber
                status
                id
            }
        }
    `
}
