<div style="width: 100%; height: 100%;" *ngIf="urlPDFOK">
    <div class="options">
        <button class="option_item" (click)="zoomIn()" title="Aumentar">
            <i class="fa fa-search-plus" aria-hidden="true"></i>
        </button>

        <button class="option_item" (click)="zoomOut()" title="Disminuir">
            <i class="fa fa-search-minus" aria-hidden="true"></i>
        </button>

        <a class="option_item" [href]="url" title="Descargar PDF" download>
            <i class="fa fa-download" aria-hidden="true"></i>
        </a>
    </div>

    <pdf-viewer [src]="urlPDFOK" [render-text]="true" [fit-to-page]="true" [zoom]="zoom" original-size="false"
                style="display: block;">
    </pdf-viewer>
</div>
