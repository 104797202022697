import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Table} from "../../../../api/graphql"

interface Response {
    getTable: Table
}

interface Param {
    idTable: string
}

@Injectable({
    providedIn: 'root'
})
export class GetTableQuery extends Query<Response, Param> {
    document = gql`
        query ($idTable: ID!) {
            getTable(idTable: $idTable) {
                id
                chairsNumber
                tableNumber
                status
                createdAt
            }
        }
    `
}
