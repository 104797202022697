import {Injectable} from '@angular/core'
import {Mutation, Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Category, CategoryUpdate} from '../../../../api/graphql'

interface Response {
    updateCategory: Category
}

interface Param {
    idCategory: string,
    category: CategoryUpdate
}

@Injectable({
    providedIn: 'root'
})
export class UpdateCategoryMutation extends Mutation<Response, Param> {
    document = gql`
        mutation($idCategory: ID!, $category: CategoryUpdate!){
            updateCategory(idCategory: $idCategory, category: $category){
                id,
                description,
                name,
                registeredAt
            }
        }
    `
}
