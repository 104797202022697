import {Category, Order, OrderDetail, OrderDetailInput, OrderDetailUpdate} from '../../../api/graphql'

export abstract class OrderDetailRepository {
    public abstract getOrderDetails(): Promise<OrderDetail[]>

    public abstract getOrderDetail(idOrderDetail: string): Promise<OrderDetail>

    public abstract addOrderDetail(orderDetail: OrderDetailInput): Promise<OrderDetail>

    public abstract updateOrderDetail(idOrderDetail: string, orderDetail: OrderDetailUpdate): Promise<OrderDetail>

    public abstract removeOrderDetail(idOrderDetail: string): Promise<Boolean>

    public abstract getOrders(): Promise<Order[]>

    public abstract getOrderDetailsByIDOrder(idOrder: string): Promise<OrderDetail[]>

    public abstract getOrder(idOrder: string): Promise<Order>
    public abstract getCategories(): Promise<Category[]>
}
