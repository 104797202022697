import {Injectable} from '@angular/core'
import gql from 'graphql-tag'
import {Mutation} from 'apollo-angular'
import {Table, TableInput} from '../../../../api/graphql'

interface Response {
    addTable: Table
}

interface Param {
    table: TableInput
}

@Injectable({
    providedIn: 'root'
})
export class AddTableMutation extends Mutation<Response, Param> {
    document = gql`
        mutation ($table: TableInput!) {
            addTable(table: $table) {
                createdAt
                tableNumber
                status
                chairsNumber
                id
            }
        }
    `
}
