import {Injectable} from "@angular/core";
import {Category, Food, FoodInput, FoodUpdate, Media2, MediaInput} from "../../../api/graphql";
import {FoodsRepository} from "../domain/foods.repository";
import {GetFoodsQuery,  } from "./graphql/getFoods.query";
import { GetCategoriesQuery } from './graphql/getCategories.query'
import {AddFoodMutation} from "./graphql/addFood.mutation";
import {AddMediaToFoodMutation} from "./graphql/addMediaToFood.mutation";
import {RemoveFoodMutation} from "./graphql/removeFood.mutation";
import {GetFoodQuery} from "./graphql/getFood.query";
import {UpdateFoodMutation} from "./graphql/updateFood.mutation";
import {RemoveMediaOfFoodMutation} from "./graphql/removeMediaOfFood.mutation";

@Injectable({
    providedIn: 'root'
})
export class FoodsRepositoryService implements FoodsRepository {
    constructor(private getFoodsQuery: GetFoodsQuery,
                private getCategoriesQuery: GetCategoriesQuery,
                private getFoodQuery: GetFoodQuery,
                private addFoodMutation: AddFoodMutation,
                private addMediaToFoodMutation: AddMediaToFoodMutation,
                private removeFoodMutation: RemoveFoodMutation,
                private updateFoodMutation: UpdateFoodMutation,
                private removeMediaOfFoodMutation: RemoveMediaOfFoodMutation) {
    }

    async getCategories(): Promise<Category[]> {
        try {
            const query$ = this.getCategoriesQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getCategories
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }



    async addMediaToFood(idFood: string, media: MediaInput): Promise<Media2> {
        try {
            const mutation$ = this.addMediaToFoodMutation.mutate({idFood, media}, {fetchPolicy: 'network-only'})
            const resp = await mutation$.toPromise()
            return resp.data.addMediaToFood
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async getFood(idFood: string): Promise<Food> {
        try {
            const query$ = this.getFoodQuery.fetch({idFood}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getFood
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async getFoods(): Promise<Food[]> {
        try {
            const query$ = this.getFoodsQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getFoods
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async removeFood(idFood: string): Promise<boolean> {
        try {
            const mutation$ = this.removeFoodMutation.mutate({idFood}, {fetchPolicy: 'network-only'})
            const resp = await mutation$.toPromise()
            return  resp.data.removeFood
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async removeMediaOfFood(idFood: string, idMedia: string): Promise<boolean> {
        try {
            const mutation$ = this.removeMediaOfFoodMutation.mutate({idFood, idMedia}, {fetchPolicy: 'network-only'})
            const resp = await mutation$.toPromise()
            return resp.data.removeMediaOfFood
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }
    async updateFood(idFood: string, food: FoodUpdate): Promise<Food> {
        try {
            const mutation$ = this.updateFoodMutation.mutate({idFood, food}, {fetchPolicy: 'network-only'})
            const resp = await mutation$.toPromise()
            return resp.data.updateFood
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async addFood(food: FoodInput): Promise<Food> {
        try {
            const mutation$ = this.addFoodMutation.mutate({food}, {fetchPolicy: 'network-only'})
            const resp = await mutation$.toPromise()
            return resp.data.addFood
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

}
