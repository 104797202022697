<div class="modal-header">
    <div class="float-left">
        <h5>{{ title }}</h5>
    </div>
</div>

<div class="modal-body">
    <div class="row clearfix">
        <div class="col-12">
            <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="maintainAspectRatio" [aspectRatio]="aspectRatio"
                format=" png" (imageCropped)="imageCropped($event)">
            </image-cropper>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col">
                <button class="btn btn-primary mr-2" (click)="okEditImgUser()">OK</button>
                <button class="btn btn-outline-secondary" (click)="cancelEditImgUser()">Cancelar</button>
            </div>
        </div>
    </div>
</div>