import {Injectable} from '@angular/core'
import {CategoryRepository} from '../domain/category.repository'
import {GetCategoriesQuery} from './graphql/getCategories.query'
import {Category, CategoryInput, CategoryUpdate} from '../../../api/graphql'
import {AddCategoryMutation} from './graphql/addCategory.mutation'
import {RemoveCategoriesMutation} from './graphql/removeCategory.mutation'
import {GetCategoryQuery} from './graphql/getCategory.query'
import {UpdateCategoryMutation} from './graphql/updateCategoryMutation'

@Injectable({
    providedIn: 'root'
})
export class CategoryRepositoryService implements CategoryRepository {
    constructor(private getCategoriesQuery: GetCategoriesQuery,
                private getCategoryQuery: GetCategoryQuery,
                private addCategoriesMutation: AddCategoryMutation,
                private removeCategoryMutation: RemoveCategoriesMutation,
                private updateCategoryMutation: UpdateCategoryMutation) {

    }

    async removeCategory(idCategory: string): Promise<boolean> {
        try {
            const mutation$ = this.removeCategoryMutation.mutate({idCategory}, {fetchPolicy: 'network-only'})
            const resp = await mutation$.toPromise()
            return resp.data.removeCategory
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async getCategories(): Promise<Category[]> {
        try {
            const query$ = this.getCategoriesQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getCategories
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async getCategory(idCategory: string): Promise<Category> {
        try {
            const query$ = this.getCategoryQuery.fetch({idCategory}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getCategory
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }

    }

    async addCategory(category: CategoryInput): Promise<Category> {
        try {
            const query$ = this.addCategoriesMutation.mutate({category}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.addCategory
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async updateCategory(idCategory: string, category: CategoryUpdate): Promise<Category> {
        try {
            const query$ = this.updateCategoryMutation.mutate({idCategory, category}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.updateCategory
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

}
