import {Injectable} from '@angular/core'
import {Mutation, Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Category} from "../../../../api/graphql"

interface Response {
    removeCategory: boolean
}

interface Param {
    idCategory: string
}

@Injectable({
    providedIn: 'root'
})
export class RemoveCategoriesMutation extends Mutation<Response, Param> {
    document = gql`
        mutation ($idCategory: ID!){
            removeCategory(idCategory:  $idCategory)
        }
    `
}
