import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core'
import {Subscription} from 'rxjs'
import {LoginApi} from '../services/login.api'
import {IUser} from '../../sys-admin/models/user'

@Directive({
    selector: '[appIfRoles]'
})
export class IfRolesDirective implements OnInit, OnDestroy {

    private subscription: Subscription[] = []
    @Input()
    set appIfRoles(val: Array<string>) {
        this.arra = val
    }
    arra: string[] = []

    constructor(
        private viewContainerRef: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private loginService: LoginApi
    ) {
    }

    ngOnInit() {
        // this.subscription.push(
        //     this.loginService.getRoles().subscribe((res) => {
        //         const user: IUser = res.data.logint
        //         const a = []
        //         user.roles.forEach(value => {
        //             a.push(value.name)
        //         })
        //         if (!a) {
        //             this.viewContainerRef.clear()
        //         }
        //         const idx = a.findIndex((element) => this.arra.indexOf(element) !== -1)
        //         if (idx < 0) {
        //             this.viewContainerRef.clear()
        //         } else {
        //             this.viewContainerRef.createEmbeddedView(this.templateRef)
        //         }
        //     })
        // )
        this.subscription.push(
            this.loginService.getUser().subscribe((user) => {
                const a = []
                user.roles.forEach(value => {
                    a.push(value.name)
                })
                if (!a) {
                    this.viewContainerRef.clear()
                }
                const idx = a.findIndex((element) => this.arra.indexOf(element) !== -1)
                if (idx < 0) {
                    this.viewContainerRef.clear()
                } else {
                    this.viewContainerRef.createEmbeddedView(this.templateRef)
                }
            })
        )
    }

    public ngOnDestroy(): void {
        this.subscription.forEach((subscription: Subscription) => subscription.unsubscribe())
    }
}
