import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Food} from '../../../../api/graphql'

interface Response {
    getFoodsByCategory: Food[]
}

interface Param {
    idCategory: string
}

@Injectable({
    providedIn: 'root'
})
export class GetFoodsByCategoryQuery extends Query<Response, Param> {
    document = gql`
        query($idCategory: ID!) {
            getFoodsByCategory(idCategory: $idCategory) {
                category {
                    description
                    id
                    name
                    registeredAt
                }
                description
                id
                images {
                    date
                    id
                    name
                    size
                    url
                }
                name
                price
                registeredAt
                type
            }
        }
    `
}
