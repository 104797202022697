import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import * as _ from 'lodash'
import {ActivatedRoute, Router} from '@angular/router'
import {Pagination} from '../../models/pagination.entity'

@Component({
    selector: 'app-nf-table-pagination',
    templateUrl: './nf-table-pagination.component.html',
    styleUrls: ['./nf-table-pagination.component.scss']
})
export class NFTablePaginationComponent implements OnInit {
    sizes = [100, 500, 1000]
    totalNumberPages: number = 0

    _totalItems: number = 0
    @Input()
    set totalItems(total: number) {
        this._totalItems = total
        this.calculateTotalNumberPages()
        if (this.page > 1 && this.page > this.totalNumberPages) {
            this.resetCurrentPage()
            this.lbOnChangePagination.emit({
                page: this.page,
                size: this.size
            })
        }
    }

    get totalItems(): number {
        return this._totalItems
    }

    @Input() totalFilterItems: number | undefined
    @Input() showResults: boolean = true
    @Output() lbOnChangePagination: EventEmitter<Pagination> = new EventEmitter
    @Output() page: number = 0

    size: number = this.sizes[0]

    constructor(private router: Router,
                private activateRoute: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.loadURLParams()
        this.calculateTotalNumberPages()
    }

    updateNumberItems(number: number) {
        this.size = number
        this.resetCurrentPage()
        this.calculateTotalNumberPages()

        this.lbOnChangePagination.emit({
            page: this.page,
            size: this.size
        })
        this.updateURLParams()
    }

    calculateTotalNumberPages() {
        let totalNumberPages
        if (isNaN(this.totalFilterItems)) {
            totalNumberPages = Math.ceil(this.totalItems / this.size)
        } else {
            totalNumberPages = Math.ceil(this.totalFilterItems / this.size)
        }
        this.totalNumberPages = (totalNumberPages <= 0) ? 1 : totalNumberPages
    }

    resetCurrentPage() {
        this.page = 0
    }

    changeCurrentPage(direction: string) {
        if (direction === 'left') {
            this.page -= 1
        } else {
            this.page += 1
        }
        this.lbOnChangePagination.emit({
            page: this.page,
            size: this.size
        })
        this.updateURLParams()
    }

    loadURLParams() {
        const params = this.activateRoute.snapshot.queryParams
        if (params.hasOwnProperty('page')) {
            const page = parseInt(params['page'], 10)
            this.page = page
        }
        if (params.hasOwnProperty('size')) {
            const size = parseInt(params['size'], 10)
            for (const size_ of this.sizes) {
                if (size_ === size) {
                    this.size = size
                    break
                }
            }
        }
        this.lbOnChangePagination.emit({
            page: this.page,
            size: this.size
        })
    }

    updateURLParams() {
        const params = _.cloneDeep(this.activateRoute.snapshot.queryParams)
        params['page'] = this.page
        params['size'] = this.size
        this.router.navigate([], {
            queryParams: params
        })
    }

}
