import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {OrderDetail} from '../../../../api/graphql'

interface Response {
    getOrderDetailsByIDOrder: OrderDetail[]
}

interface Param {
    idOrder: string
}

@Injectable({
    providedIn: 'root'
})
export class GetOrderDetailsByIDOrderQuery extends Query<Response, Param> {
    document = gql`
        query($idOrder: ID!) {
            getOrderDetailsByIDOrder(idOrder: $idOrder) {
                additionalPrice
                food {
                    category {
                        description
                        id
                        name
                        registeredAt
                    }
                    description
                    id
                    name
                    price
                    registeredAt
                    type
                }
                history{
                    date
                    id
                    status
                    user {
                        id
                        names
                    }
                }
                id
                observations
                quantity
                status
            }
        }
    `
}
