import {Component, Input, OnInit} from '@angular/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'
import {PlatformLocation} from '@angular/common'

@Component({
    selector: 'app-show-pdf-modal',
    templateUrl: './show-pdf-modal.component.html',
    styleUrls: ['./show-pdf-modal.component.css']
})
export class ShowPdfModalComponent implements OnInit {

    @Input() urlReport: string = ''
    @Input() arrayUrls = null

    fullUrl: string
    arrayfullUrl: string[] = []

    constructor(private modal: NgbActiveModal,
                private location: PlatformLocation) {
        location.onPopState(() => this.closeModal())
    }

    ngOnInit() {
        // console.log(this.arrayUrls)

        if (this.arrayUrls) {
            this.arrayUrls.map(data => {
                this.arrayfullUrl.push(`${data.url}`)
            })
        }
        // console.log(this.arrayfullUrl)
        if (!this.urlReport) {
            return this.fullUrl = ''
        }
        this.fullUrl = `${this.urlReport}`
        // console.log(this.arrayUrls)
    }

    dismissModal(reason: any = null) {
        this.modal.dismiss(reason)
    }

    closeModal(result: any = null) {
        this.modal.close(result)
    }
}
