import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {SidebarService} from '../../services/sidebar.service';
import {IRoute} from './models/route.interface';
import {Module} from '../../../../../app/api/graphql'

@Component({
    selector: 'app-core-navegation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
    public sidebarVisible: boolean = true
    _routes: IRoute[] = []
    @Input()
    set routes(pag: IRoute[]) {
        this._routes = pag
    }
    // @Output() toggleMenu = new EventEmitter<string>()

    get routes(): IRoute[] {
        return this._routes
    }

    constructor(private sidebarService: SidebarService,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    toggleFullWidth(e): void {
        // this.toggleMenu.emit(e)
        this.sidebarService.toggle()
        this.sidebarVisible = this.sidebarService.getStatus()
        console.log(this.sidebarVisible)
        this.cdr.detectChanges()
    }

}
