import {Category, CategoryInput, CategoryUpdate} from '../../../api/graphql'

export abstract class CategoryRepository {
    public abstract getCategories(): Promise<Category[]>

    public abstract getCategory(id: string): Promise<Category>

    public abstract updateCategory(id: string, updatedCategory: CategoryUpdate): Promise<Category>

    public abstract addCategory(newCategory: CategoryInput): Promise<Category>

    public abstract removeCategory(id: string): Promise<boolean>
}
