import {Injectable} from '@angular/core'
import {Mutation} from 'apollo-angular'
import gql from 'graphql-tag'
import {Food, FoodInput} from "../../../../api/graphql"

interface Response {
    addFood: Food
}

interface Param {
    food: FoodInput
}

@Injectable({
    providedIn: 'root'
})
export class AddFoodMutation extends Mutation<Response, Param> {
    document = gql`
        mutation ($food: FoodInput!){
            addFood(food: $food){
                id
                name
                description
                category {
                    id
                    name
                    description
                }
            }
        }
    `
}
