import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Food} from '../../../../api/graphql'

interface Response {
    getFoods: Food[]
}

@Injectable({
    providedIn: 'root'
})
export class GetFoodsQuery extends Query<Response> {
    document = gql`
        query {
            getFoods {
                category {
                    description
                    id
                    name
                    registeredAt
                }
                description
                id
                images {
                    date
                    id
                    name
                    size
                    url
                }
                name
                price
                registeredAt
                type
            }
        }
    `
}
