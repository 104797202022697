<div class="d-flex justify-content-between align-items-center lb-table-pagination lb-padding">
    <div class="dropdown">
        <button class="dropdown-toggle d-flex align-items-center px-4 border border-2 bg-white"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="button">
            <div class="fs-14 fw-bolder lb-table-pagination-dropdown-text">
                {{size}} resultados por página
            </div>
            <div class="fs-21 lb-table-pagination-dropdown-arrow">
                <i class="fa fa-angle-down"></i>
            </div>
        </button>
        <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu w-100 px-0 fs-13-5"
            id="dropdownItemsPagination">
            <ng-container *ngFor="let size of sizes; let iSize=index">
                <li (click)="updateNumberItems(size)" class="cursor-pointer">
                    <a class="dropdown-item px-4 py-3">{{size}} resultados por página</a>
                </li>
            </ng-container>
        </ul>
    </div>

    <div *ngIf="showResults"
         class="lb-hiden-text px-2 fs-13-5 fw-bold text-nowrap cursor-default lb-table-pagination-info">
        {{(totalFilterItems >= 0) ? (totalFilterItems + ' resultados de ' + totalItems) : (totalItems + (totalItems > 1 ? ' resultados' : ' resultado'))}}
    </div>

    <div class="btn-group">
        <button (click)="changeCurrentPage('left')"
                [disabled]="page === 0"
                class="col px-4 border border-2 bg-white fs-21
                  {{page === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}}">
            <i class="fa fa-angle-left text-secondary"></i>
        </button>
        <div
                class="col d-flex align-items-center px-4 border border-2 border-start-0 border-end-0 bg-white fw-bold fs-13 cursor-default">
            {{page+1}}/{{totalNumberPages}}
        </div>
        <button (click)="changeCurrentPage('right')"
                [disabled]="page === totalNumberPages-1"
                class="col px-4 border border-2 bg-white fs-21
                  {{page === (totalNumberPages-1) ? 'cursor-not-allowed' : 'cursor-pointer'}}">
            <i class="fa fa-angle-right text-secondary"></i>
        </button>
    </div>

</div>


<div class=" lb-table-pagination lb-hiden-text2 lb-text-center">
    <div *ngIf="showResults" class="px-2 fs-13-5 fw-bold text-nowrap cursor-default lb-table-pagination-info">
        {{(totalFilterItems >= 0) ? (totalFilterItems + ' results out of ' + totalItems) : (totalItems + (totalItems > 1 ? ' resultados' : ' resultado'))}}
    </div>
</div>
