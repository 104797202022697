import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ImageCroppedEvent } from 'ngx-image-cropper'
import { SwalService } from '../../services/swal.service'

@Component({
    selector: 'app-modal-crop-image',
    templateUrl: './crop-image-modal.component.html',
    styleUrls: ['./crop-image-modal.component.css'],
})
export class CropImageModalComponent implements OnInit {
    @Input() title: string = ''
    @Input() imageChangedEvent: Event = null
    @Input() maintainAspectRatio: Boolean = false
    @Input() aspectRatio: number = 1 / 1
    @Output() eventOkImage = new EventEmitter<File>()
    @Output() eventCancelImage = new EventEmitter<String>()
    croppedImage: any = ''

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit() { }

    cancelEditImgUser() {
        this.croppedImage = ''
        this.activeModal.dismiss()
        this.eventCancelImage.emit('cancel')
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64
    }

    okEditImgUser() {
        if (this.croppedImage !== '') {
            fetch(this.croppedImage)
                .then((res) => res.blob())
                .then((blob) => this.eventOkImage.emit(new File([blob], 'file.png')))
                .catch((err) => SwalService.error())
        }
        this.activeModal.dismiss()
    }
}
