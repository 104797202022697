<nav class="navbar navbar-fixed-top">
    <div class="container-fluid">
        <div class="navbar-btn">
            <button type="button" class="btn-toggle-offcanvas" (click)="toggleSideMenu()"><i
                    class="lnr lnr-menu fa fa-bars"></i></button>
        </div>
        <div class="navbar-brand">
            <a style="cursor: default">
                <img [src]="logoEnterprice" alt="Logo" style="max-height: 28px" class="img-responsive logo">
            </a>
        </div>

        <div class="navbar-right">
            <div id="navbar-menu">
                <app-items-tr></app-items-tr>
            </div>
        </div>
    </div>
</nav>
