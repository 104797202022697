import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {UserRepository} from './domain/user.repository'
import {HttpClientModule} from '@angular/common/http'
// import {UserRestRepositoryMock} from './reppository/rest/user.rest.repository.mock'
import {UserRestRepository} from './reppository/rest/user.rest.repository'


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        // {provide: UserRepository, useClass: UserRestRepositoryMock},
        {provide: UserRepository, useClass: UserRestRepository}
        // {
        //     provide: Bar,
        //     useFactory: (foo) => new Bar(foo),
        //     deps: [Foo]
        // }
    ]
})
export class UsersModule {
}
