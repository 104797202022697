// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    api: '/graphql',
    // api: 'http://localhost:4040/graphql',
    storage: '/storage/upload',
    // endpointSubscription: 'ws://' + window.location.host
    endpointSubscription: (window.location.protocol === 'https:' ? 'wss://' : 'ws://') + window.location.host,
    logo: 'assets/images/vanidosasv3.png',
    logoSvg: 'assets/images/logo.png',
    url: 'https://613ea6d4e9d92a0017e1723e.mockapi.io/api/v1/'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
