import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoryRepository} from "./domain/category.repository";
import {CategoryRepositoryService} from "./repository/category.repository.service";

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        {provide: CategoryRepository, useClass: CategoryRepositoryService}
    ]
})
export class CategoriesModule {
}
