import {Injectable} from '@angular/core'
import {Mutation, Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Category, CategoryInput} from '../../../../api/graphql'

interface Response {
    addCategory: Category
}

interface Param {
    category: CategoryInput
}

@Injectable({
    providedIn: 'root'
})
export class AddCategoryMutation extends Mutation<Response, Param> {
    document = gql`
        mutation ($category: CategoryInput!){
            addCategory(category: $category)
            {
                description
                name
                registeredAt
                id
            }
        }
    `
}
