import {Category, Food, FoodInput, FoodUpdate, Media2, MediaInput} from "../../../api/graphql";

export abstract  class FoodsRepository {
    // FOODS
    public abstract getFoods(): Promise<Food[]>
    public abstract getFood(id: string): Promise<Food>
    public abstract addFood(foodInput: FoodInput): Promise<Food>
    public abstract removeFood(id: string): Promise<boolean>
    public abstract updateFood(id: string, food: FoodUpdate): Promise<Food>
    public abstract removeMediaOfFood(idFood: string, idMedia: string): Promise<boolean>
    public abstract addMediaToFood(idFood: string, media: MediaInput): Promise<Media2>
    // CATEGORIES
    public  abstract getCategories(): Promise<Category[]>
}
