<div class="rounded-circle user-photo" style="height: 50px" *ngIf="isLoading">
    <div class="lds-dual-ring"></div>
</div>
<img [src]="photoUser"
     class="rounded-circle user-photo"
     [ngStyle]="{visibility:isLoading?'hidden':''}"
     (load)="hideLoader()" (error)="errorLoadImg()" alt=""/>
<!--            (error)="user.photo = '/core/assets/icons/user.png'"-->
<div class="dropdown" ngbDropdown>
    <span>Bienvenido,</span>
    <a href="javascript:void(0);" class="dropdown-toggle user-name" ngbDropdownToggle>
        <strong>{{user?.person?.firstName}}</strong>
    </a>
    <ul class="dropdown-menu dropdown-menu-right account" ngbDropdownMenu>
        <li><a [routerLink]="['/core/my-profile']"><i class="icon-user"></i>Mi Perfil</a></li>
        <!--                    <li><a [routerLink]="['/app/app/app-inbox']"><i class="icon-envelope-open"></i>Messages</a></li>-->
        <!--                    <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li>-->
        <li class="divider"></li>
        <li><a [routerLink]="['/authentication/page-login']"
               (click)="logout()"><i class="icon-power"></i>Salir</a>
        </li>
    </ul>
</div>
