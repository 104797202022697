import {Injectable} from '@angular/core'
import {Mutation} from 'apollo-angular'
import gql from 'graphql-tag'

interface Response {
    removeTable: boolean
}

interface Params {
    idTable: string
}

@Injectable({
    providedIn: 'root'
})
export class RemoveTableMutation extends Mutation<Response, Params> {
    document = gql`
        mutation($idTable: ID!) {
            removeTable(idTable: $idTable)
        }
    `
}
