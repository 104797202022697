import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {AdminComponent} from 'core/modules/sys-shared/components/admin-view/admin.component'
import {AuthGuardService} from 'core/modules/sys-shared/services/auth-guard.service'
// import {AdminComponent} from '../core/modules/sys-shared/components/admin-view/admin.component'

const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        canLoad: [AuthGuardService],
        children: [
            {
                path: '', redirectTo: 'core', pathMatch: 'full'
            },
            {
                path: 'core',
                // canLoad: [AuthGuardService],
                loadChildren: () => import('core/core.module').then(m => m.CoreModule)
            },
            {
                path: 'foods',
                loadChildren: () => import('app/modules/foods/ui/foods.module').then(m => m.FoodsModule)
            },
            {
                path: 'view-tables',
                loadChildren: () => import('app/modules/view-table/ui/view-table.module').then(m => m.ViewTableModule)
            },
            {
                path: 'products',
                loadChildren: () => import('app/modules/products/UI/products/products.module').then(m => m.ProductsModule)
            },
            {
                path: 'view-principal',
                loadChildren: () => import('app/modules/view-principal-options/view-principal-options.module')
                    .then(m => m.ViewPrincipalOptionsModule)
            },
            {
                path: 'orders-waiter',
                loadChildren: () => import('app/modules/orders-waiter/ui/waiter/waiter.module').then(m => m.WaiterModule)
            },
            {
                path: 'category',
                loadChildren: () => import('app/modules/categories/ui/categories.module').then(m => m.CategoriesModule)
            },
            {
                path: 'order-detail',
                loadChildren: () => import('app/modules/order-detail/ui/order-detail.module').then(m => m.CategoriesModule)
            },
            {
                path: 'clothing',
                loadChildren: () => import('app/modules/clothing/clothing.module').then(m => m.ClothingModule)
            },
            {
                path: 'sales',
                loadChildren: () => import('app/modules/sales/sales.module').then(m => m.SalesModule)
            },
            {
                path: 'rental',
                loadChildren: () => import('app/modules/rental/rental.module').then(m => m.RentalModule)
            },
            {
                path: 'management',
                loadChildren: () => import('app/modules/management/management.module').then(m => m.ManagementModule)
            },
        ]
    },
    {
        path: 'chefs',
        loadChildren: () => import('app/modules/chefs/ui/chefs.module').then(m => m.ChefsModule)
    },

    {
        path: 'authentication',
        loadChildren: () => import('core/modules/sys-auth/sys-auth.module').then(m => m.SysAuthModule)
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
