import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoryRepository} from "../categories/domain/category.repository";
import {CategoryRepositoryService} from "../categories/repository/category.repository.service";
import {OrdersRepository} from "./domain/waiter.repository";
import {OrderRepositoryService} from "./reppository/order.repository.service";

@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        {provide: OrdersRepository, useClass: OrderRepositoryService}
    ]
})
export class OrdersWaiterModule {
}
