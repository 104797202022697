import {Injectable} from '@angular/core'
import {Category, Food, Order, OrderDetail, OrderDetailInput, OrderInput, OrderUpdate, Table} from '../../../api/graphql'
import {GetOrdersQuery} from './graphql/getOrders.query'
import {OrdersRepository} from '../domain/waiter.repository'
import {GetOrderQuery} from './graphql/getOrder.query'
import {UpdateOrderMutation} from './graphql/updateOrder.mutation'
import {AddOrderMutation} from './graphql/addOrder.mutation'
import {RemoveOrderMutation} from './graphql/removeOrder.mutation'
import {GetFoodsQuery} from './graphql/getFoods.query'
import {GetCategoriesQuery} from './graphql/getCategories.query'
import {GetFoodsByCategoryQuery} from './graphql/getFoodsByCategory.query'
import {AddOrderDetailMutation} from './graphql/addOrderDetail.mutation'
import {GetOrderDetailQuery} from './graphql/getOrderDetail.query'
import {AddOrderDetailsMutation} from './graphql/addOrderDetails.mutation'
import {GetTableQuery} from './graphql/getTable.query'
import {GetTablesQuery} from './graphql/getTables.query'
import {UpdateOrderCanceledMutation} from './graphql/updateOrderCanceled.mutation'

@Injectable({
    providedIn: 'root'
})
export class OrderRepositoryService implements OrdersRepository {
    constructor(private getOrdersQuery: GetOrdersQuery,
                private getOrderQuery: GetOrderQuery,
                private updateOrderMutation: UpdateOrderMutation,
                private addOrderMutation: AddOrderMutation,
                private removeOrderMutation: RemoveOrderMutation,
                private updateOrderCanceledMutation: UpdateOrderCanceledMutation,
                private getFoodsQuery: GetFoodsQuery,
                private getCategoriesQuery: GetCategoriesQuery,
                private getFoodsByCategoryQuery: GetFoodsByCategoryQuery,
                private addOrderDetailMutation: AddOrderDetailMutation,
                private getOrderDetailQuery: GetOrderDetailQuery,
                private addOrderDetailsMutation: AddOrderDetailsMutation,
                private getTablesQuery: GetTablesQuery,
                private getTableQuery: GetTableQuery) {

    }

    async getOrders(): Promise<Order[]> {
        try {
            const query$ = this.getOrdersQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrders
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async getCategories(): Promise<Category[]> {
        try {
            const query$ = this.getCategoriesQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getCategories
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async getOrder(idOrder: string): Promise<Order> {
        try {
            const query$ = this.getOrderQuery.fetch({idOrder}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrder
        } catch (e) {
            console.log(e)
            throw  new Error(e)
        }
    }

    async updateOrder(order: OrderUpdate, idOrder: string): Promise<Order> {
        try {
            const query$ = this.updateOrderMutation.mutate({idOrder, order}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.updateOrder
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }
    async addOrder(order: OrderInput): Promise<Order> {
        try {
            const query$ = this.addOrderMutation.mutate({order}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.addOrder
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async removeOrder(idOrder: string): Promise<Boolean> {
        try {
            const mutation$ = this.removeOrderMutation.mutate({idOrder}, {fetchPolicy: 'network-only'})
            const resp = await mutation$.toPromise()
            return resp.data.removeOrder
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }
    async updateOrderCanceled(idOrder: string): Promise<Boolean> {
        try {
            const mutation$ = this.updateOrderCanceledMutation.mutate({idOrder}, {fetchPolicy: 'network-only'})
            const resp = await mutation$.toPromise()
            return resp.data.updateOrderCanceled
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }
    async getFoods(): Promise<Food[]> {
        try {
            const query$ = this.getFoodsQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getFoods
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async getFoodsByCategory(idCategory: string): Promise<Food[]> {
        try {
            const query$ = this.getFoodsByCategoryQuery.fetch({idCategory}, {fetchPolicy: 'network-only'})
            const {data} = await query$.toPromise()
            return data.getFoodsByCategory
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async addOrderDetail(orderDetail: OrderDetailInput): Promise<OrderDetail> {
        try {
            const query$ = this.addOrderDetailMutation.mutate({orderDetail}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.addOrderDetail
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }
    async getOrderDetail(idOrderDetail: string): Promise<OrderDetail> {
        try {
            const query$ = this.getOrderDetailQuery.fetch({idOrderDetail}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrderDetail
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }
    async addOrderDetails(orderDetails: OrderDetailInput[]): Promise<OrderDetail[]> {
        try {
            const query$ = this.addOrderDetailsMutation.mutate({orderDetails}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.addOrderDetails
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }
    async getTable(idTable: string): Promise<Table> {
        try {
            const query$ = this.getTableQuery.fetch({idTable}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getTable
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    async getTables(): Promise<Table[]> {
        try {
            const query$ = this.getTablesQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getTables
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

}
