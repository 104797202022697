import {Injectable} from '@angular/core'
import {Mutation} from 'apollo-angular'
import gql from 'graphql-tag'
import {User, UserUpdate} from '../../../../app/api/graphql'

interface Response {
    updateUser: User
}

interface Param {
    idUser: string
    user: UserUpdate
}

@Injectable({
    providedIn: 'root'
})
export class UpdateUserMutation extends Mutation<Response, Param> {
    document = gql`
        mutation($idUser: ID!, $user: UserUpdate!){
            updateUser(idUser: $idUser, user: $user){
                id
            }
        }`
}
