import {Component, Input, OnInit} from '@angular/core'

@Component({
    selector: 'app-nf-spinner',
    templateUrl: './nf-spinner.component.html',
    styleUrls: ['./nf-spinner.component.scss']
})
export class NFSpinnerComponent {

    @Input() spinnerType: string = 'circle'
    @Input() message: string = ''

    constructor() {
    }
}
