import {Food, Order, OrderDetail, OrderDetailInput, OrderInput, OrderUpdate, Table} from '../../../api/graphql'

export abstract class OrdersRepository {
    public abstract getOrder(idOrder: string): Promise<Order>

    public abstract getOrders(): Promise<Order[]>

    public abstract addOrder(order: OrderInput): Promise<Order>

    public abstract removeOrder(idOrder: string): Promise<Boolean>

    public abstract updateOrder(order: OrderUpdate, idOrder: string): Promise<Order>

    public abstract getFoods(): Promise<Food[]>

    public abstract addOrderDetail(orderDetail: OrderDetailInput): Promise<OrderDetail>

    public abstract getOrderDetail(idOrderDetail: string): Promise<OrderDetail>

    public abstract addOrderDetails(orderDetail: OrderDetailInput[]): Promise<OrderDetail[]>

    public abstract getTable(idTable: string): Promise<Table>

    public abstract getTables(): Promise<Table[]>

    public abstract updateOrderCanceled(idOrder: string): Promise<Boolean>
}
