import {BranchOffice, Brand, Category, NewProduct, Product, Variant} from './product.entity'

export abstract class ProductRepository {
    public abstract getProductByCode(id: string): Promise<Product>
    public abstract getProducts(): Promise<Product[]>
    public abstract updateProduct(id: string, updatedProduct: Product): Promise<string>
    public abstract saveProduct( newProduct: NewProduct ): Promise<string>
    public abstract deleteProduct( id: string ): Promise<string>
    public abstract getCategories(): Promise<Category[]>
    public abstract getVariants(id: string): Promise<Variant[]>
    public abstract getBranchOffices(): Promise<BranchOffice[]>
    public abstract getBrands(id: string): Promise<Brand[]>
}
