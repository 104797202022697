import {Injectable} from '@angular/core'
import {Mutation} from 'apollo-angular'
import gql from 'graphql-tag'
import {Food, FoodInput, FoodUpdate} from "../../../../api/graphql"

interface Response {
    updateFood: Food
}

interface Param {
    idFood: string,
    food: FoodUpdate
}

@Injectable({
    providedIn: 'root'
})
export class UpdateFoodMutation extends Mutation<Response, Param> {
    document = gql`
        mutation ($idFood: ID!, $food: FoodUpdate!){
            updateFood(idFood: $idFood, food: $food){
                id
                name
                description
                category{
                    id
                    name
                    description
                }
            }
        }
    `
}
