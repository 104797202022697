<div id="left-sidebar" class="sidebar" *ngIf="sidebarVisible"
     style="overflow-y:scroll;">
    <div class="sidebar-scroll">
        <div class="user-account">
            <app-user-photo [user]="user">
            </app-user-photo>
            <hr>
        </div>
        <!-- Nav tabs -->
        <app-menu-tabs [user]="user"
                       [darkClass]="darkClass"
                       [navTab]="navTab"
                       [sidebarVisible]="sidebarVisible"
                       [themeClass]="themeClass"
                       [currentActiveMenu]="currentActiveMenu"
                       [currentActiveSubMenu]="currentActiveSubMenu"
                       (activeInactiveMenuEvent)="activeInactiveMenu($event)">
        </app-menu-tabs>
    </div>
</div>
