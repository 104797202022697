import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap'
import { ThemeService } from '../../sys-shared/services/theme.service'
import { environment } from '../../../../environments/environment'

@Component({
    selector: 'app-core-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    providers: [NgbDropdownConfig]
})
export class HeaderComponent implements OnInit {

    public logo: string = 'assets/images/vanidosasv3.png'
    logoEnterprice: string = environment.logo

    @Input() showNotifMenu: boolean = false
    @Input() showToggleMenu: boolean = false
    @Input() darkClass: string = ''
    @Output() toggleSettingDropMenuEvent = new EventEmitter()
    @Output() toggleNotificationDropMenuEvent = new EventEmitter()

    constructor(private config: NgbDropdownConfig,
        private themeService: ThemeService,
        // private getNotificationsGQL: GetNotificationsGQL,
        // private subNotificationsSubscriptionGQL: SubNotificationsSubscriptionGQL,
        // private updateNotificationReadGQL: UpdateNotificationReadGQL,
        // private router: Router,
        // private toastr: ToastrService
    ) {
        config.placement = 'bottom-right'
    }

    ngOnInit() {
    }

    toggleSettingDropMenu() {
        this.toggleSettingDropMenuEvent.emit()
    }

    toggleNotificationDropMenu() {
        this.toggleNotificationDropMenuEvent.emit()
    }

    toggleSideMenu() {
        this.themeService.showHideMenu()
    }
}
