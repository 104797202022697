<span [ngSwitch]="spinnerType" class="spinner-wrapper">
  <div *ngSwitchCase="'bounce'" class="spinner-{{spinnerType}}">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
  <div *ngSwitchCase="'circle'" class="spinner-{{spinnerType}}">
  </div>
  <div *ngSwitchCase="'rectangle'" class="spinner-{{spinnerType}}">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
  </div>
    <span style="margin-top: 10px">{{message}}</span>
</span>
