import {Component, OnInit} from '@angular/core'
import {LoginApi} from '../../sys-shared/services/login.api'

@Component({
    selector: 'app-items-tr',
    templateUrl: './items-tr.component.html',
    styleUrls: ['./items-tr.component.css']
})
export class ItemsTrComponent implements OnInit {

    constructor(private loginService: LoginApi) {
    }

    ngOnInit(): void {
    }

    logoutBtn() {
        this.loginService.logout()
    }

}
