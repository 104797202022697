import {Injectable} from '@angular/core'
import {Mutation, Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Order, OrderUpdate} from '../../../../api/graphql'

interface Response {
    updateOrder: Order
}

interface Param {
    idOrder: string,
    order: OrderUpdate
}

@Injectable({
    providedIn: 'root'
})
export class UpdateOrderMutation extends Mutation<Response, Param> {
    document = gql`
        mutation($idOrder: ID!, $order: OrderUpdate!) {
            updateOrder(idOrder: $idOrder, order: $order) {
                code
                createdAt
                id
                status
                tables{
                    chairsNumber
                    createdAt
                    id
                    status
                    tableNumber
                }
            }
        }
    `
}
