import {Injectable} from "@angular/core";
import {ChefsRepository} from "../domain/chefs.repository";
import {Category, Order, OrderDetail} from "../../../api/graphql";
import {GetOrdersQuery} from "./graphql/getOrders.query";
import {GetOrderDetailsByIDOrderQuery} from "./graphql/getOrderDetailsByIDOrder.query";
import {GetCategoriesQuery} from "./graphql/getCategories.query";

@Injectable({
    providedIn: 'root'
})
export class ChefsRepositoryService implements ChefsRepository {
    constructor(private getOrdersQuery: GetOrdersQuery,
                private getOrderDetailsByIDOrderQuery: GetOrderDetailsByIDOrderQuery,
                private getCategoriesQuery: GetCategoriesQuery) {
    }

    async  getOrders(): Promise<Order[]> {
        try {
            const query$ = this.getOrdersQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrders
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
    }

    async getOrderDetailsByIDOrder(idOrder: string): Promise<OrderDetail[]> {
        try {
            const query$ = this.getOrderDetailsByIDOrderQuery.fetch({idOrder}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrderDetailsByIDOrder
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
    }

    async getCategories(): Promise<Category[]> {
        try {
            const query$ = this.getCategoriesQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getCategories
        } catch (error) {
            console.log(error)
            throw new Error(error)
        }
    }
}
