<!-- Page Loader -->
<div class="body {{themeClass}} {{smallScreenMenu}} {{darkClass}}">


    <app-core-page-loader *ngIf="!isStopLoading"></app-core-page-loader>

    <div id="wrapper" *ngIf="isStopLoading">

        <!-- Overlay For Sidebars -->

        <app-core-header [darkClass]="darkClass"></app-core-header>

        <app-core-sidebar [sidebarVisible]="sidebarService.sidebarVisible"
                     [navTab]="navTab"
                     [currentActiveMenu]="currentActiveMenu"
                     [currentActiveSubMenu]="currentActiveSubMenu"
                     (activeInactiveMenuEvent)="activeInactiveMenu($event)">
        </app-core-sidebar>

        <div id="main-content" [ngClass]="{'layout-fullwidth':!sidebarService.sidebarVisible}">
            <router-outlet (activate)="getActiveRoutes()"></router-outlet>
        </div>
    </div>
</div>
