import {Component, Input, Output, EventEmitter, OnDestroy, OnInit} from '@angular/core'
import {Subject} from 'rxjs'
import {Router} from '@angular/router'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {UpdateUserMutation} from '../../sys-admin/api/updateUser.mutation'
import {IUser} from '../../sys-admin/models/user'
import {LoginApi} from '../../sys-shared/services/login.api'
import {Module, View} from '../../../../app/api/graphql'
import {ThemeService} from '../../sys-shared/services/theme.service'

interface ModuleViews {
    name: string | null,
    code: string | null,
    icon: string | null,
    views: View[] | null
}

@Component({
    selector: 'app-core-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {

    @Input() sidebarVisible: boolean = true
    @Input() navTab: string = 'menu'
    @Input() currentActiveMenu
    @Input() currentActiveSubMenu
    @Output() activeInactiveMenuEvent = new EventEmitter()
    public themeClass: string = 'theme-cyan'
    public darkClass: string = ''
    private ngUnsubscribe = new Subject()

    user: IUser = null
    moduleViews: ModuleViews[] = []

    formFilter: FormGroup = new FormGroup({
        concession: new FormControl(null, [Validators.required]),
    })

    constructor(private themeService: ThemeService,
                private loginService: LoginApi) {

    }

    async ngOnInit() {
        this.loginService.getUser().subscribe(user => {
            this.user = user
            if (!this.user) {
                this.loginService.loadMenu()
            } else {
                this.darkClass = this.user.theme
                this.themeClass = this.user.skin
                this.themeService.changeDarkMode(this.darkClass)
                this.themeService.themeChange(this.themeClass)
                // console.log('Theme enable, ', this.darkClass)
            }
        })
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }

    activeInactiveMenu(menuItem: string) {
        this.activeInactiveMenuEvent.emit({'item': menuItem})
    }
}
