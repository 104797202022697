import {Injectable} from '@angular/core'
import {Mutation, Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {OrderDetail, OrderDetailInput, OrderDetailUpdate} from '../../../../api/graphql'

interface Response {
    removeOrderDetail: Boolean
}

interface Param {
    idOrderDetail: string
}

@Injectable({
    providedIn: 'root'
})
export class RemoveOrderDetailMutation extends Mutation<Response, Param> {
    document = gql`
        mutation($idOrderDetail: ID!)
        {
            removeOrderDetail(idOrderDetail: $idOrderDetail)
        }
    `
}

