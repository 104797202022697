import {Component, Input, OnInit} from '@angular/core'
import {IUser} from '../../sys-admin/models/user'
import {LoginApi} from '../../sys-shared/services/login.api'

@Component({
    selector: 'app-user-photo',
    templateUrl: './user-photo.component.html',
    styleUrls: ['./user-photo.component.css']
})
export class UserPhotoComponent implements OnInit {

    private _user: IUser = null
    photoUser: string = ''
    isLoading: boolean = true

    @Input() set user(user: IUser) {
        this._user = {...user}
        this.photoUser = this._user?.person?.photo?.url
    }

    get user() {
        return this._user
    }

    constructor(private loginService: LoginApi) {
    }

    ngOnInit(): void {
    }

    hideLoader() {
        this.isLoading = false
    }

    errorLoadImg() {
        if (this.user) {
            this.photoUser = '/core/assets/icons/user.png'
        }
        this.isLoading = false
    }

    logout() {
        this.loginService.logout()
    }

}
