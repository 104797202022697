import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Order, OrderDetail} from '../../../../api/graphql'

interface Response {
    getOrder: Order
}

interface Param {
    idOrder: string
}

@Injectable({
    providedIn: 'root'
})
export class GetOrderQuery extends Query<Response, Param> {
    document = gql`
        query($idOrder: ID!) {
            getOrder(idOrder: $idOrder) {
                code
                createdAt
                id
                status
                tables {
                    chairsNumber
                    createdAt
                    id
                    status
                    tableNumber
                }
                waiter {
                    id
                    dni
                    names
                }
            }
        }
    `
}
