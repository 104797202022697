import {Injectable} from '@angular/core'
import {Mutation} from 'apollo-angular'
import gql from 'graphql-tag'
import {Food, FoodInput, FoodUpdate} from "../../../../api/graphql"

interface Response {
    removeMediaOfFood: boolean
}

interface Params {
    idFood: string,
    idMedia: string
}

@Injectable({
    providedIn: 'root'
})
export class RemoveMediaOfFoodMutation extends Mutation<Response, Params> {
    document = gql`
        mutation ($idFood: ID!, $idMedia: ID!){
            removeMediaOfFood(idFood: $idFood, idMedia: $idMedia)
        }
    `
}
