import {Injectable} from '@angular/core'
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot, UrlSegment
} from '@angular/router'
import {Observable} from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {

    constructor(public router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean> | Promise<boolean> | boolean {
        if (this.isLoggedIn !== true) {
            this.router.navigate(['authentication/page-login'])
        }
        return true
    }

    get isLoggedIn(): boolean {
        const token = localStorage.getItem('token')
        return token !== null
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
        console.log('---is login: ', this.isLoggedIn)
        if (this.isLoggedIn !== true) {
            this.router.navigate(['authentication/page-login'])
        }
        return this.isLoggedIn
    }

}
