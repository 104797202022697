import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {ChefsRepository} from "./domain/chefs.repository";
import {ChefsRepositoryService} from "./repository/chefs.repository.service";
@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        { provide: ChefsRepository, useClass: ChefsRepositoryService }
    ]
})
export class ChefsModule { }
