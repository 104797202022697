import {Injectable} from '@angular/core'
import {Mutation, Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {OrderDetail, OrderDetailInput} from '../../../../api/graphql'

interface Response {
    addOrderDetails: OrderDetail[]
}

interface Param {
    orderDetails: OrderDetailInput[]
}

@Injectable({
    providedIn: 'root'
})
export class AddOrderDetailsMutation extends Mutation<Response, Param> {
    document = gql`
        mutation($orderDetails: [OrderDetailInput]!) {
            addOrderDetails(orderDetails: $orderDetails) {
                additionalPrice
                createdAt
                food{
                    category{
                        description
                        id
                        name
                        registeredAt
                    }
                    description
                    id
                    images{
                        date
                        id
                        name
                        size
                        url
                    }
                    name
                    price
                    registeredAt
                    type
                }
                id
                observations
                quantity
                status
            }
        }

    `
}
// mutation($orderDetail: OrderDetailInput!) {
//     addOrderDetail(orderDetail: $orderDetail) {
//         additionalPrice
//         createdAt
//         food {
//             category {
//                 description
//                 id
//                 name
//                 registeredAt
//             }
//             description
//             id
//             name
//             price
//             registeredAt
//             type
//         }
//         history {
//             date
//             id
//             status
//             user {
//                 id
//                 dni
//                 names
//             }
//         }
//         id
//         observations
//         quantity
//         status
//     }
// }
