import { Component, Input, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MediaService } from '../../services/media/media.service'
import { SwalService } from '../../services/swal.service'
import { SweetAlertResult } from 'sweetalert2'

@Component({
    selector: 'app-pdf-modal',
    templateUrl: './pdf-modal.component.html',
    styleUrls: ['./pdf-modal.component.css']
})
export class PdfModalComponent implements OnInit {
    url: string = ''
    urlPDFOK: any = ''
    sw: SweetAlertResult = null
    zoom: number = 1

    @Input()
    set urlPDF(val: string) {
        this.url = val
        const sw = SwalService.progressBlock('Descargando 0%')

        this.mediaService
            .downloadMediaPercentage(val)
            .subscribe(
                (res) => {
                    if (res.state === 'DONE') {
                        this.blobPDF = res.content
                        SwalService.closeAlert()
                    } else {
                        SwalService.getSwalTitle().textContent = `Descargando ${res.progress}%`
                    }
                }, (err) => {
                    SwalService.error()
                }
            )

    }

    @Input()
    set blobPDF(val: Blob) {
        const reader = new FileReader()
        reader.onload = (e: any) => {
            this.urlPDFOK = e.target.result
        }
        reader.readAsArrayBuffer(val)
    }

    constructor(
        private http: HttpClient,
        private mediaService: MediaService
    ) {
    }

    ngOnInit() {
    }

    zoomIn() {
        if (this.zoom > 4) {
            return
        }
        this.zoom = this.zoom + 0.1
    }

    zoomOut() {
        if (this.zoom < 0.1) {
            return
        }
        this.zoom = this.zoom - 0.1;
    }

}
