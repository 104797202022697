<div class="block-header">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <ul class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="javascript:void(0);" class="btn btn-xs btn-link btn-toggle-fullwidth m-0 p-0"
                       (click)="toggleFullWidth($event)">
                        <i class="lnr lnr-menu fa fa-bars"></i>
                    </a>
                </li>
                <li class="breadcrumb-item" *ngFor="let route of routes" [ngClass]="{'active':route?.active}">
                    <div *ngIf="route?.routerLink; else liOption3">
                        <a [routerLink]="route?.routerLink"
                           [queryParams]="route?.queryParams">
                            {{route?.label}}
                        </a>
                    </div>
                    <ng-template #liOption3>
                        {{route?.label}}
                    </ng-template>
                </li>
            </ul>
        </div>
    </div>
</div>
