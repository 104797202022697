import {Component, AfterViewInit, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core'
import {SidebarService} from '../../services/sidebar.service'
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router'
import {ThemeService} from '../../services/theme.service'
import {Title} from '@angular/platform-browser'
import {takeUntil} from 'rxjs/operators'
import {Subject} from 'rxjs'

import 'rxjs/add/operator/filter'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/mergeMap'

@Component({
    selector: 'app-core-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements AfterViewInit, OnInit, OnDestroy {

    public title = 'lucid'
    public isStopLoading = false
    public showNotifMenu = false
    public showToggleMenu = false
    public navTab = 'menu'
    public currentActiveMenu = 'light'
    public currentActiveSubMenu: any
    public themeClass = 'theme-cyan'
    public smallScreenMenu = ''
    public darkClass = ''
    private ngUnsubscribe = new Subject()

    constructor(public sidebarService: SidebarService,
                private cd: ChangeDetectorRef,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private themeService: ThemeService,
                private titleService: Title) {
        this.activatedRoute.url.pipe(takeUntil(this.ngUnsubscribe)).subscribe(url => {
            this.isStopLoading = false
            this.getActiveRoutes()
        })

        this.themeService.smallScreenMenuShow.pipe(takeUntil(this.ngUnsubscribe)).subscribe(showMenuClass => {
            console.log(showMenuClass)
            this.smallScreenMenu = showMenuClass
        })
    }


    ngOnInit() {
        const that = this
        this.router.events
            .filter((event) => event instanceof NavigationEnd)
            .map(() => this.activatedRoute)
            .map((route) => {
                that.themeService.hideMenu()
                while (route.firstChild) {
                    route = route.firstChild
                }
                return route
            })
            .filter((route) => route.outlet === 'primary')
            .mergeMap((route) => route.data)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((event) => this.titleService.setTitle(event['title']))
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }

    ngAfterViewInit() {
        const that = this
        setTimeout(function () {
            that.isStopLoading = true

            that.themeService.themeClassChange.pipe(takeUntil(that.ngUnsubscribe)).subscribe(themeClass => {
                that.themeClass = themeClass
            })

            that.themeService.darkClassChange.pipe(takeUntil(that.ngUnsubscribe)).subscribe(darkClass => {
                that.darkClass = darkClass
            })
            that.cd.detectChanges()
        }, 0)

    }

    getActiveRoutes() {
        const segments: Array<string> = this.router.url.split('/')
        this.currentActiveMenu = segments[1]
        this.currentActiveSubMenu = segments[2]
    }

    activeInactiveMenu($event: any) {
        if ($event.item && $event.item === this.currentActiveMenu) {
            this.currentActiveMenu = ''
        } else {
            this.currentActiveMenu = $event.item
        }
    }

}
