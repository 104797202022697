import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Category} from "../../../../api/graphql"

interface Response {
    getCategory: Category
}

interface Param {
    idCategory: string
}

@Injectable({
    providedIn: 'root'
})
export class GetCategoryQuery extends Query<Response> {
    document = gql`
        query ($idCategory: ID!) {
            getCategory(idCategory: $idCategory) {
                description
                id
                name
                registeredAt
            }
        }
    `
}
