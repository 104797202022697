import {Injectable} from '@angular/core'
import {Mutation, Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {OrderDetail, OrderDetailInput, OrderDetailUpdate} from '../../../../api/graphql'

interface Response {
    updateOrderDetail: OrderDetail
}

interface Param {
    idOrderDetail: string,
    orderDetail: OrderDetailUpdate
}

@Injectable({
    providedIn: 'root'
})
export class UpdateOrderDetailMutation extends Mutation<Response, Param> {
    document = gql`
        mutation($idOrderDetail: ID!, $orderDetail: OrderDetailUpdate!) {
            updateOrderDetail(idOrderDetail: $idOrderDetail, orderDetail: $orderDetail) {
                additionalPrice
                createdAt
                food {
                    category {
                        description
                        id
                        name
                        registeredAt
                    }
                    description
                    id
                    name
                    price
                    registeredAt
                    type
                }
                history {
                    date
                    id
                    status
                    user {
                        id
                        dni
                        names
                    }
                }
                id
                observations
                quantity
                status
            }
        }

    `
}
