import {Pipe, PipeTransform} from '@angular/core'
import {DateService} from '../services/date/date.service'

@Pipe({
    name: 'age'
})
export class AgePipe implements PipeTransform {

    constructor(private dateService: DateService) {
    }

    transform(date: string): number {
        const today = new Date()
        const birthDate = new Date(this.dateService.to_YYY_MM_DD(date))
        let age = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
        return age
    }

}
