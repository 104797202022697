import {Injectable} from '@angular/core'
import {Mutation} from 'apollo-angular'
import gql from 'graphql-tag'

interface Response {
    updateOrderCanceled: Boolean
}

interface Param {
    idOrder: string
}

@Injectable({
    providedIn: 'root'
})
export class UpdateOrderCanceledMutation extends Mutation<Response, Param> {
    document = gql`
        mutation($idOrder:ID!){
            updateOrderCanceled(idOrder:$idOrder)
        }
    `
}
