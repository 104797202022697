import {Injectable} from '@angular/core'
import {Mutation} from 'apollo-angular'
import gql from 'graphql-tag'

interface Response {
    removeFood: boolean
}

interface Params {
    idFood: string
}

@Injectable({
    providedIn: 'root'
})
export class RemoveFoodMutation extends Mutation<Response, Params> {
    document = gql`
        mutation ($idFood: ID!){
            removeFood(idFood: $idFood )
        }
    `
}
