import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'

import {OrderDetailRepository} from './domain/order-detail.repository'
import {OrderDetailRepositoryService} from './repository/order-detail.repository.service'


@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        {provide: OrderDetailRepository, useClass: OrderDetailRepositoryService}
    ]
})
export class OrderDetailsModule {
}
