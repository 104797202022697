import { Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { LoginApi } from './login.api'
import { IUser } from '../../sys-admin/models/user'
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class UsersGuardGuard implements CanActivate {

    constructor(private loginService: LoginApi, private router: Router) {
    }

    canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log('can activate user')
        return this.loginService.getInitialUser().then((user: IUser) => {
            const valid = this.checkUsers(childRoute, user)
            if (!valid) { this.router.navigate([user.modules[0].views[0].url]) }
            return valid
        })

    }

    checkUsers(childRoute: ActivatedRouteSnapshot, user: IUser): boolean {
        const route = childRoute.pathFromRoot
            .map(v => v.url.map(segment => segment.toString()).join('/'))
            .join('/').replace('//', '/')
        // console.log(route)
        // const urlChild = [...childRoute.url]
        // const [{path: pathChild}] = urlChild
        // const urlParent = [...childRoute.parent.url]
        // const [{path: pathParent}] = urlParent
        // console.log(`/${pathParent}/${pathChild}`)
        if (!user) { return false }

        for (let i = 0; i < user.modules.length; i++) {
            for (let j = 0; j < user.modules[i].views.length; j++) {
                const tempUrl = user.modules[i].views[j].url
                if (tempUrl === route) {
                    return true
                }
            }
        }
        return true
    }

}
