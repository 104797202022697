import {NgModule} from '@angular/core'
import {HttpClientModule} from '@angular/common/http'
import {Apollo, APOLLO_OPTIONS} from 'apollo-angular'
import {HttpLink} from 'apollo-angular/http'
import {InMemoryCache, ApolloLink} from '@apollo/client/core'
import {setContext} from '@apollo/client/link/context'
import {environment} from '../environments/environment'

const uri = environment.api

export function createApollo(httpLink: HttpLink) {
    const basic = setContext((operation, context) => ({
        headers: {
            Accept: 'charset=utf-8'
        }
    }))

    const auth = setContext((operation, context) => {
        const token = localStorage.getItem('token')

        if (token === null) {
            return {}
        } else {
            return {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        }
    })

    const link = ApolloLink.from([basic, auth, httpLink.create({uri})])
    const cache = new InMemoryCache({
        addTypename: false
    })

    return {
        link,
        cache
    }
}

@NgModule({
    exports: [
        HttpClientModule
    ],
    providers: [{
        provide: APOLLO_OPTIONS,
        useFactory: createApollo,
        deps: [HttpLink]
    }]
})
export class GraphQLModule {
}
