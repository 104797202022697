import {Injectable} from '@angular/core'
import * as moment from 'moment-timezone'

@Injectable({
    providedIn: 'root'
})
export class DateService {

    constructor() {
    }

    to_YYY_MM_DD(value: string): string {
        if (value) {
            return moment(value).format('YYYY-MM-DD')
        } else {
            return ''
        }
    }

    convertDateToLocal(date, length = 19): string {
        if (date) {
            const dateLocal = moment(date, 'YYYY-MM-DDTHH:mm:ssZZ').tz('America/Lima').format()
            return dateLocal.substr(0, length)
        } else {
            return null
        }
    }

    convertDateShortToAmericaLima(dateShort): string {
        if (dateShort) {
            return moment.tz(dateShort, 'YYYY-MM-DD', 'America/Lima').format()
        } else {
            return null
        }
    }

    convertDateToUTC(date: string): string {
        return moment(date, 'YYYY-MM-DDTHH:mm:ssZZ').toISOString()
    }
}
