<ul class="nav navbar-nav">
    <li class="dropdown" ngbDropdown>
        <a class="dropdown-toggle icon-menu"
           ngbDropdownToggle
           style="cursor: pointer">
            <i class="icon-equalizer"></i>
        </a>
        <ul class="dropdown-menu user-menu menu-icon" ngbDropdownMenu>
            <li class="menu-heading">CONFIGURACIONES</li>
            <li>
                <a [routerLink]="['/core/my-profile']">
                    <i class="icon-note"></i> <span>Mi Perfil</span>
                </a>
            </li>
        </ul>
    </li>
    <li>
        <a [routerLink]="['/authentication/page-login']"
           (click)="logoutBtn()"
           class="icon-menu d-none d-sm-block">
            <i class="icon-login"></i></a>
    </li>
</ul>
