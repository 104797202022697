import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Company, User} from '../../../../app/api/graphql'

interface Response {
    login: User
    getCompany: Company
}

@Injectable({
    providedIn: 'root'
})
export class GetLoginTQuery extends Query<Response> {
    document = gql`
        query {
            login {
                id
                nick
                person{
                    firstName
                    lastname
                    motherLastname
                    typeDocument
                    numberDocument
                    photo {
                        id
                        url
                    }
                    phone
                    email
                }
                token
                modules {
                    id
                    name
                    code
                    icon
                    color
                    views {
                        id
                        name
                        url
                        icon
                    }
                }
                roles {
                    name
                    description
                    accesses {
                        view {
                            id
                            name
                            url
                            icon
                        }
                        order
                        position
                    }
                }
                theme
                skin
            }
            getCompany {
                name
                logo
            }
        }`
}
