import {Injectable} from '@angular/core'
import {Mutation} from 'apollo-angular'
import gql from 'graphql-tag'
import { Media2, MediaInput} from "../../../../api/graphql"

interface Response {
    addMediaToFood: Media2
}

interface Params {
    idFood: string,
    media: MediaInput
}

@Injectable({
    providedIn: 'root'
})
export class AddMediaToFoodMutation extends Mutation<Response, Params> {
    document = gql`
        mutation ($idFood: ID!, $media: MediaInput!){
            addMediaToFood(idFood: $idFood, media: $media){
                id
                name
                url
            }
        }
    `
}
