import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Category} from "../../../../api/graphql"

interface Response {
    getCategories: Category[]
}

@Injectable({
    providedIn: 'root'
})
export class GetCategoriesQuery extends Query<Response> {
    document = gql`
        query {
            getCategories {
                description
                id
                name
                createdAt
            }
        }
    `
}
