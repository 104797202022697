import {HttpClient} from '@angular/common/http'
import {Component, Input, OnInit} from '@angular/core'

@Component({
    selector: 'app-image-modal',
    templateUrl: './image-modal.component.html',
    styleUrls: ['./image-modal.component.css'],
})
export class ImageModalComponent implements OnInit {

    urls: string[] = []

    @Input()
    set urlsImage(val: string | File | []) {
        if (typeof val === 'object' && !Array.isArray(val)) {
            const reader = new FileReader()
            reader.onload = (e: any) => {
                const url = e.target.result
                this.urls = [url]
            }
            // reader.readAsArrayBuffer(val) // TODO
        } else if (typeof val === 'object' && Array.isArray(val)) {
            this.urls = val
        } else {
            this.urls = [val]
        }
    }

    constructor() {
    }

    ngOnInit() {
    }
}
