import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProductRepository } from './domain/product.repository';
import { ProductRepositoryMockService } from './repository/product.repository.mock.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
      { provide: ProductRepository, useClass: ProductRepositoryMockService }
  ]
})
export class ProductsModule { }
