import {Injectable} from '@angular/core'
import {Category, Order, OrderDetail, OrderDetailInput, OrderDetailUpdate} from '../../../api/graphql'
import {OrderDetailRepository} from '../domain/order-detail.repository'
import {GetOrderDetailsQuery} from './graphql/getOrderDetails.query'
import {GetOrderDetailQuery} from './graphql/getOrderDetail.query'
import {AddOrderDetailMutation} from './graphql/addOrderDetail.mutation'
import {UpdateOrderDetailMutation} from './graphql/updateOrderDetail.mutation'
import {RemoveOrderDetailMutation} from './graphql/removeOrderDetail.mutation'
import {GetOrdersQuery} from './graphql/getOrders.query'
import {GetOrderDetailsByIDOrderQuery} from './graphql/getOrderDetailByIDOrder.query'
import {GetOrderQuery} from './graphql/getOrder.query'
import {GetCategoriesQuery} from "../../categories/repository/graphql/getCategories.query";

@Injectable({
    providedIn: 'root'
})
export class OrderDetailRepositoryService implements OrderDetailRepository {
    constructor(private getOrderDetailsQuery: GetOrderDetailsQuery,
                private getOrderDetailQuery: GetOrderDetailQuery,
                private addOrderDetailMutation: AddOrderDetailMutation,
                private updateOrderDetailMutation: UpdateOrderDetailMutation,
                private removeOrderDetailMutation: RemoveOrderDetailMutation,
                private getOrdersQuery: GetOrdersQuery,
                private getOrderDetailByIdOrder: GetOrderDetailsByIDOrderQuery,
                private getOrderQuery: GetOrderQuery,
                private getCategoriesQuery: GetCategoriesQuery) {
    }

    async getOrders(): Promise<Order[]> {
        try {
            const query$ = this.getOrdersQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrders
        } catch (e) {
            console.log(e)
            throw new Error
        }
        throw new Error('Method not implemented.')
    }

    async getOrderDetails(): Promise<OrderDetail[]> {
        try {
            const query$ = this.getOrderDetailsQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrderDetails
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }

    async getOrderDetail(idOrderDetail: string): Promise<OrderDetail> {
        try {
            const query$ = this.getOrderDetailQuery.fetch({idOrderDetail}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrderDetail
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }

    async addOrderDetail(orderDetail: OrderDetailInput): Promise<OrderDetail> {
        try {
            const query$ = this.addOrderDetailMutation.mutate({orderDetail}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.addOrderDetail
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }

    async updateOrderDetail(idOrderDetail: string, orderDetail: OrderDetailUpdate): Promise<OrderDetail> {
        try {
            const query$ = this.updateOrderDetailMutation.mutate({idOrderDetail, orderDetail}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.updateOrderDetail
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }

    async removeOrderDetail(idOrderDetail: string): Promise<Boolean> {
        try {
            const query$ = this.removeOrderDetailMutation.mutate({idOrderDetail}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.removeOrderDetail
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }

    async getOrderDetailsByIDOrder(idOrder: string): Promise<OrderDetail[]> {
        try {
            const query$ = this.getOrderDetailByIdOrder.fetch({idOrder}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrderDetailsByIDOrder
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }

    async getOrder(idOrder: string): Promise<Order> {
        try {
            const query$ = this.getOrderQuery.fetch({idOrder}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getOrder
        } catch (e) {
            console.log(e)
            throw new Error
        }
    }
    async getCategories(): Promise<Category[]> {
        try {
            const query$ = this.getCategoriesQuery.fetch({}, {fetchPolicy: 'network-only'})
            const resp = await query$.toPromise()
            return resp.data.getCategories
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }
}
