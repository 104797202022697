import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FoodsRepository} from "./domain/foods.repository";
import {FoodsRepositoryService} from "./repository/foods.repository.service";



@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        { provide: FoodsRepository, useClass: FoodsRepositoryService }
    ]
})
export class FoodsModule { }
