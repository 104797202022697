import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {OrderDetail} from '../../../../api/graphql'

interface Response {
    getOrderDetail: OrderDetail
}

interface Param {
    idOrderDetail: string
}

@Injectable({
    providedIn: 'root'
})
export class GetOrderDetailQuery extends Query<Response, Param> {
    document = gql`
        query($idOrderDetail: ID!){
            getOrderDetail(idOrderDetail: $idOrderDetail){
                additionalPrice
                createdAt
                food {
                    category {
                        description
                        id
                        name
                        registeredAt
                    }
                    description
                    id
                    name
                    price
                    registeredAt
                    type
                }
                history {
                    date
                    id
                    status
                    user{
                        id
                        dni
                        names
                    }
                }
                id
                observations
                quantity
                status
            }
        }
    `
}
