import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse} from '@angular/common/http'
import {scan} from 'rxjs/operators'

export interface Download {
    state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
    progress: number
    content: Blob | null
}

export interface Upload {
    state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
    progress: number
    url: String | null
}

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    constructor(private http: HttpClient) {
    }

    private static isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
        return event.type === HttpEventType.Response
    }

    private static isHttpProgressEvent(
        event: HttpEvent<unknown>
    ): event is HttpProgressEvent {
        return (
            event.type === HttpEventType.DownloadProgress ||
            event.type === HttpEventType.UploadProgress
        )
    }

    uploadMedia(file: File, path: string): Observable<any> {
        const formData = new FormData()
        formData.append('file', file)
        // path options --> documents-module photos reports icons sectors
        formData.append('path', path)
        return this.http.post('/storage/upload', formData)
    }

    //
    uploadMediaProgress(file: File, path: string): Observable<any> {
        const formData = new FormData()
        formData.append('file', file)
        // path options --> documents-module photos reports icons sectors
        formData.append('path', path)
        return this.http.post('/storage/upload', formData, {
            reportProgress: true,
            observe: 'events'
        })
    }

    uploadMediaPercentage(file: File, path: string): Observable<Upload> {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('path', path)
        return this.http.post('/storage/upload', formData, {
            reportProgress: true,
            observe: 'events',
            responseType: 'json'
        }).pipe(
            scan(
                (previous: Upload, event: HttpEvent<Body>): Upload => {
                    if (MediaService.isHttpProgressEvent(event)) {
                        return {
                            progress: event.total
                                ? Math.round(
                                    (100 * event.loaded) / event.total
                                )
                                : previous.progress,
                            state: 'IN_PROGRESS',
                            url: null
                        }
                    }

                    if (MediaService.isHttpResponse(event)) {
                        return {
                            progress: 100,
                            state: 'DONE',
                            url: event.body['url']
                        }
                    }

                    return previous
                },
                {state: 'PENDING', progress: 0, url: null}
            )
        )
    }

    downloadMediaPercentage(url: string): Observable<Download> {
        return this.http
            .get(url, {
                reportProgress: true,
                observe: 'events',
                responseType: 'blob'
            }).pipe(
                scan(
                    (previous: Download, event: HttpEvent<Blob>): Download => {
                        if (MediaService.isHttpProgressEvent(event)) {
                            return {
                                progress: event.total
                                    ? Math.round(
                                        (100 * event.loaded) / event.total
                                    )
                                    : previous.progress,
                                state: 'IN_PROGRESS',
                                content: null
                            }
                        }

                        if (MediaService.isHttpResponse(event)) {
                            return {
                                progress: 100,
                                state: 'DONE',
                                content: event.body
                            }
                        }

                        return previous
                    },
                    {state: 'PENDING', progress: 0, content: null}
                )
            )
    }
}
