import {Injectable} from '@angular/core'
import {Mutation} from 'apollo-angular'
import gql from 'graphql-tag'
import {Order, OrderInput} from '../../../../api/graphql'

interface Response {
    addOrder: Order
}

interface Param {
    order: OrderInput
}

@Injectable({
    providedIn: 'root'
})
export class AddOrderMutation extends Mutation<Response, Param> {
    document = gql`
        mutation($order:OrderInput!){
            addOrder(order: $order){
                code
                createdAt
                id
                status
                tables{
                    chairsNumber
                    createdAt
                    id
                    status
                    tableNumber
                }
            }
        }
    `
}
