import {Injectable} from '@angular/core'
import {Query} from 'apollo-angular'
import gql from 'graphql-tag'
import {Table} from '../../../../api/graphql'

interface Response {
    getTables: Table[]
}

@Injectable({
    providedIn: 'root'
})
export class GetTablesQuery extends Query<Response> {
    document = gql`
        query {
            getTables {
                chairsNumber
                createdAt
                hidden
                id
                status
                tableNumber
            }
        }

    `
}
